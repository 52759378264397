import { uInfo } from './useToken';
const HostName = (window.location.host == '192.168.5.88:5000' || window.location.host == 'localhost:5000') ?
    'http://192.168.5.55/profitcoach/api/' :
    window.location.host == 'dev.profitcoach.app' ?
        "https://devws.profitcoach.app/api/"
        : 
        window.location.host == 'test.profitcoach.app' ?
        "https://testws.profitcoach.app/api/" :
        "https://ws.profitcoach.app/api/";

export const ApiUrl = HostName;
const HostWebUrl = (window.location.host == '192.168.5.88:5000' || window.location.host == 'localhost:5000') ?
    'http://' + window.location.host :
    window.location.host == 'dev.profitcoach.app' ?
        "https://dev.profitcoach.app"
        :
     window.location.host == 'test.profitcoach.app' ?
        "https://test.profitcoach.app":
        "https://profitcoach.app";
export const HostUrl = HostWebUrl;

export const ToastAutoClose = 100;
export const PusherKey = "3086524ac1732fefac3c";
export const logoLightPng = require('./assets/images/logo.png');
export const logoLightSmallPng = require('./assets/images/logo-small.png');
export const ProductName = "PROFITCOACH™";

export const AttachementsUrl = (window.location.host == '192.168.5.88:5000' || window.location.host == 'localhost:5000') ?
    'http://192.168.5.55/profitcoach/crmdoc/' :
    window.location.host == 'dev.profitcoach.app' ?
        "https://devws.profitcoach.app/crmdoc/"
        :
    window.location.host == 'test.profitcoach.app' ?
        "https://testws.profitcoach.app/crmdoc/"
        :
        "https://ws.profitcoach.app/crmdoc/";

const web = (window.location.host == '192.168.5.88:5000' || window.location.host == 'localhost:5000') ?
    'http://192.168.5.55/profitcoach' :
    window.location.host == 'dev.profitcoach.app' ?
        "https://devws.profitcoach.app"
        :
     window.location.host == 'test.profitcoach.app' ?
        "https://testws.profitcoach.app"
        :
        "https://ws.profitcoach.app";
export const WebUrl = web;

export const MeetingUrl = "https://meet.profitcoach.app";
export const ServerIP = "72.52.254.31";

// export const FroalaLicenseKey = 'ERB2zF2E1A4B4B5C2D1rXYf1VPUGRHYZNRJb2JVOOe1HAb2zA3B2A1E1F4E1I1A10A1A6=='; //Old key
export const FroalaLicenseKey = 'TEB9iE6A5A3A3F4J3C9aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6E5C2C4C3H3G3J3==';

fetch(`${HostName}theme/` + uInfo().user_id, {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
})
    .then((response) => response.json())
    //Then with the data from the response in JSON...
    .then((response) => {
        // console.log(response.theme);
        localStorage.setItem("theme", response.theme);
    })
    //Then with the error genereted...
    .catch((error) => {
        console.error('Error:', error);
    });

const greeting = localStorage.getItem("theme");

let obj = {};

if (greeting) {
    greeting.split("\n").map((string, index) => {
        if (string) {
            let part1 = string.match('export const ' + "(.*?)" + '=')[1].replace(/\s/g, '');
            let start = `export const ${part1} =`;
            let varValue = string.match(start + "(.*?)" + ';')[1];
            obj[part1] = varValue.replaceAll("'", '')
        }
    })
}

export const bgColor = obj.bgColor;
export const btnPrimaryBgColor = obj.btnPrimaryBgColor;
export const btnPrimaryBgHoverColor = obj.btnPrimaryBgHoverColor;
export const textColor = obj.textColor;
export const btnPrimaryTextColor = obj.btnPrimaryTextColor;
export const btnPrimaryHoverText = obj.btnPrimaryHoverText;
export const btnDangerTextColor = obj.btnDangerTextColor;
export const btnDangerBgColor = obj.btnDangerBgColor;
export const btnDangerHoverText = obj.btnDangerHoverText;
export const btnDangerBgHoverColor = obj.btnDangerBgHoverColor;
export const btnInfoTextColor = obj.btnInfoTextColor;
export const btnInfoBgColor = obj.btnInfoBgColor;

export const btnInfoHoverText = obj.btnInfoHoverText;
export const btnInfoBgHoverColor = obj.btnInfoBgHoverColor;
export const navTextColor = obj.navTextColor;
export const navTextHoverColor = obj.navTextHoverColor;

//export const consumptionList = require(`./WL/localhost/lite_user_credit_criteria.json`);


// export  ProductName;