import React, { Component, Fragment } from "react";
import { Row, Col, Input, Label, Card } from "reactstrap";
import EmailSideBar from "./email-sidebar";
import EmailToolbar from "./email-toolbar";
import { toast, Flip } from "react-toastify";
import { ApiUrl } from "../../config";
import { dcrypt, bcrypt, uToken } from "../../useToken";
import { Link } from "react-router-dom";

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

const noRecordsStyle = {
  textAlign: 'center',
  padding: '20px',
  fontSize: '18px',
  color: '#888',
  borderTop: '1px solid #ddd',
  backgroundColor: '#f9f9f9',
};

const iconStyle = {
  fontSize: '24px',
  color: '#888',
  display: 'block',
  marginBottom: '10px',
};

export default class EmailInbox extends Component {
  state = {
    emails: [],
    totalEmails: 0,
    currentPage: 1,
    pageSize: 10,
    total_pages: 0,
    selectedEmails: [],
    selectAll: false,
    searchTerm: "",
    noRecordsFound: false,
  };

  componentDidMount() {
    this.getEmails();
  }

  fetchEmails = (endpoint, params = {}) => {
    return fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify(params),
    })
      .then(response => response.json());
  };

  getEmails = () => {
    const { currentPage, pageSize } = this.state;
    const report_id = new URLSearchParams(this.props.location.search).get('show')
      ? dcrypt(new URLSearchParams(this.props.location.search).get('show'))
      : '';

    const queryParams = new URLSearchParams({
      report_type: 'email',
      report_id,
      page_number: currentPage,
      page_size: pageSize,
      direction: 'in',
      status: 'received'
    }).toString();

    this.fetchEmails(`${ApiUrl}aio/report/email?${queryParams}`)
      .then(response => {
        if (response.status) {
          this.setState({
            emails: response.data.records,
            totalEmails: response.data.total,
            total_pages: Math.ceil(response.data.total / pageSize),
            noRecordsFound: response.data.records.length === 0,
          });
        } else {
          this.showToast(response.message, 'error');
        }
      })
      .catch(() => this.showToast("Error fetching emails", 'error'));
  };

  searchEmails = () => {
    const { searchTerm } = this.state;
    const queryParams = new URLSearchParams({ search: searchTerm }).toString();

    this.fetchEmails(`${ApiUrl}aio/report/email/search?${queryParams}`)
      .then(response => {
        if (response.status) {
          this.setState({
            emails: response.data.records,
            totalEmails: response.data.total,
            noRecordsFound: response.data.records.length === 0,
          });
        } else {
          this.showToast(response.message, 'error');
        }
      })
      .catch(() => this.showToast("Error fetching emails", 'error'));
  };

  showToast = (message, type) => {
    toast(message, {
      hideProgressBar: true,
      position: "top-center",
      transition: Flip,
      className: `toast-custom-cls ${type}`,
    });
  };

  handleSelectEmail = (emailId) => {
    this.setState(prevState => {
      const selectedEmails = prevState.selectedEmails.includes(emailId)
        ? prevState.selectedEmails.filter(id => id !== emailId)
        : [...prevState.selectedEmails, emailId];

      return { selectedEmails, selectAll: false };
    });
  };

  handleSelectAll = () => {
    this.setState(prevState => {
      const selectAll = !prevState.selectAll;
      const selectedEmails = selectAll ? this.state.emails.map(email => email.report_id) : [];

      return { selectedEmails, selectAll };
    });
  };

  handlePageChange = (page) => {
    if (page > 0 && page <= this.state.total_pages) {
      this.setState({ currentPage: page }, this.getEmails);
    }
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.getEmails);
  };

  handleSearchChange = (searchTerm) => {
    this.setState({ searchTerm, currentPage: 1 }, this.searchEmails);
  };

  markEmailAsRead = (record) => {
    this.fetchEmails(`${ApiUrl}get/sub/report/body/${record.sub_log_id}/${record.client_id}`, { report_id: record.report_id })
      .then(response => {
        if (response.status) {
          this.setState(prevState => ({
            emails: prevState.emails.map(email =>
              email.report_id === record.report_id ? { ...email, read_status: 1 } : email
            ),
          }));
        } else {
          this.showToast(response.message, 'error');
        }
      })
      .catch(() => this.showToast("Error marking email as read", 'error'));
  };

  render() {
    const { emails, currentPage, totalEmails, pageSize, selectedEmails, selectAll, noRecordsFound } = this.state;
    const unreadCount = emails.filter(email => email.read_status === 0).length;

    return (
      <Fragment>
        <Row>
          <Col xs="12">
            <EmailSideBar unreadCount={unreadCount} />
            <div className="email-rightbar mb-3">
              <Card>
                <EmailToolbar
                  currentPage={currentPage}
                  totalEmails={totalEmails}
                  pageSize={pageSize}
                  refreshEmails={this.getEmails}
                  selectedEmails={selectedEmails}
                  selectAll={selectAll}
                  toggleSelectAll={this.handleSelectAll}
                  onPageChange={this.handlePageChange}
                  onSearchChange={this.handleSearchChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />

                {noRecordsFound ? (
                  <div style={noRecordsStyle}>
                    <i className="fas fa-folder-open" style={iconStyle}></i>
                    <p>No records found</p>
                  </div>
                ) : (
                  <ul className="message-list">
                    {emails.map(email => (
                      <li key={email.report_id} className={email.read_status === 0 ? "unread" : ""}>
                        <div className="col-mail col-mail-1">
                          <div className="checkbox-wrapper-mail">
                            <Input
                              type="checkbox"
                              name="report_id"
                              id={`${email.report_id}`}
                              checked={selectedEmails.includes(email.report_id) || selectAll}
                              onChange={() => this.handleSelectEmail(email.report_id)}
                            />
                            <Label htmlFor={`${email.report_id}`} className="toggle" />
                          </div>
                          <Link
                            to={`/email/read/${bcrypt(email.report_id)}`}
                            className="title"
                            onClick={() => this.markEmailAsRead(email)}
                          >
                            {email.name || 'Unknown Sender'}
                          </Link>
                          <span className="star-toggle far fa-star" />
                        </div>
                        <div className="col-mail col-mail-2">
                          <Link
                            to={`/email/read/${bcrypt(email.report_id)}`}
                            className="subject"
                            onClick={() => this.markEmailAsRead(email)}
                          >
                            {email.body ? stripHtmlTags(email.body).substring(0, 50) : "No subject"} – 
                            <span className="teaser">
                              {email.body ? stripHtmlTags(email.body).substring(0, 100) : "No preview available"}
                            </span>
                          </Link>
                          <div style={{ paddingLeft: '0px' }} className="date">
                            {email.created_at || "Date not available"}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
