import React, { useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { bcrypt, uInfo } from '../../useToken';


export default function WaySideBar(props) {

    const params = React.useContext(WayBookContext);
    const [isOpen, setIsOpen] = useState('sub' + params.category_i + '-' + params.subject_i);
    const [isDocOpen, setIsDocOpen] = useState('doc' + params.category_i + '-' + params.subject_i + '-' + params.documents_i);
    const [defaultIndex] = useState(params.category_i);
    const [lists, setLists] = useState(params.lists);
    const [avgPercent, setAvgPercent] = useState(0);
    const [tempPercent, setTempPercent] = useState(0);
    const { search } = useLocation();
    const url_params = new URLSearchParams(search);
    const is_coach_section = url_params.get('sec') ? 'Yes' : "No";


    const toggleOpen = (id) => () => setIsOpen((prevOpen) => (prevOpen === id ? null : id));
    const toggleDocOpen = (id) => () =>

        setIsDocOpen((isDocOpen) => (isDocOpen === id ? null : id));

    const Subject = ({ cat_id, index, subindex, content, subject, percentage, isOpen, isDocOpen, toggle, toggleDoc, status, image, user_id, training_for, tag_training, master_id, disabled }) => {

        function calculatePercentage(part, total) {
            if (total === 0) {
                return 0; // Avoid division by zero
            }
            return Math.round((part / total) * 100);
        }

        const averagePercentage = (part, total) => {
            if (total === 0) {
                return 0; // Avoid division by zero
            }

            part = part + tempPercent;
            setTempPercent(part);

            setAvgPercent(Math.round((tempPercent / total)));

        }

        return (
            <div key={cat_id} className="subject" onDragStart={(e) => dragStart(e, subindex)} onDragEnter={(e) => dragEnter(e, subindex)}
                onDragEnd={(e) => dropTwo(e, index)}
                draggable={params.can_edit}>

                <div className={`${isOpen ? 'active' : ''} pb-1 ProgressBarHide`}>
                    <div className="step-content p-0">
                        <div className=" d-flex justify-content-between">
                            <div className='d-flex flex-column w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span><Link className={`${disabled ? 'disabled' : ''}`} disabled={disabled} to={`/trainings/${bcrypt(index)}/${bcrypt(master_id)}/${bcrypt(subindex)}/${bcrypt(cat_id)}`}>{subject}</Link></span>
                                    {uInfo().user_id && uInfo().user_id != 2 && (
                                        params.subject && params.subject_i == subindex && (
                                            <>
                                                <span className='PercentageShow'> {percentage}%</span>
                                            </>
                                        ))}
                                </div>
                                {uInfo().user_id && uInfo().user_id != 2 && (
                                    params.subject && params.subject_i == subindex && (
                                        <>
                                            <div className="progress w-100">
                                                <div role="progressbar" class="progress-bar" aria-valuemin={percentage} aria-valuemax="100" aria-valuenow={percentage} style={{ width: `${percentage}%` }}></div>
                                            </div>
                                        </>
                                    ))}
                            </div>
                            {
                                params.can_edit && (
                                    <div className='subject-action'>
                                        {
                                            status !== 'Publish' && (
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">This module is unpublished</Tooltip>}
                                                >
                                                    <i className='fas fa-info-circle'></i>
                                                </OverlayTrigger>
                                            )
                                        }
                                        <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Subject', cat_id, subject, e, training_for, tag_training, image, user_id,'left',master_id)}>
                                            <Dropdown.Toggle variant="link">
                                                <i className='fas fa-ellipsis-h'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {status === 'Publish' ? (
                                                    <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                ) : (
                                                    <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                )}
                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                <Dropdown.Item eventKey="AddDocument">Add Lesson</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
                <Collapse isOpen={isOpen} className="content-way">
                    {content}
                </Collapse>
            </div>
        );
    };


    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        e.preventDefault();


        if (!params.can_edit) {
            e.preventDefault();
        }
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e, i, k) => {
        const copyListItems = [...lists[i].sub_cat[k].sub_cat];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        let newState = [...lists];
        let two = newState[i].sub_cat[k];
        two.sub_cat = copyListItems;
        newState[i].sub_cat[k] = two;
        setLists(newState);
    };

    const dropTwo = (e, i) => {

        const copyListItems = [...lists[i].sub_cat];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        let newState = [...lists];
        newState[i].sub_cat = copyListItems;
        setLists(newState);
    };





    return (
        <div className='subjects px-3 side-change-dropdown'>
            <Accordion defaultActiveKey={defaultIndex && is_coach_section === 'No' ? [defaultIndex] : null} alwaysOpen flush className='WayBookCate'>
                {lists.map((item, i) => (
                    <Accordion.Item disabled={item.disabled} key={item.cat_id} className={`${item.disabled ? 'disabled' : ''} mainCate`} eventKey={`${i}`}>
                        {params.can_edit && (
                            <div className='category-action'>
                                <div className='d-flex align-items-center'>
                                    {item.status !== 'Publish' && (
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">This course is unpublished</Tooltip>}
                                        >
                                            <i className='fas fa-info-circle'></i>
                                        </OverlayTrigger>
                                    )}

                                    <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Category', item.cat_id, item.title, e, item.training_for, item.tag_training, '', '', 'left', item.master_id)}>
                                        <Dropdown.Toggle variant="link">
                                            <i className='fas fa-ellipsis-h'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {item.status === 'Publish' ? (
                                                <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                            ) : (
                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                            )}
                                            <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                            <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                            {item.status === 'Publish' && (
                                                <>
                                                    {item.onboard_training === 1 ? (
                                                        <Dropdown.Item disabled>OnBoard Training <i className='pl-3 fas fa-check'></i></Dropdown.Item>
                                                    ) : (
                                                        <Dropdown.Item eventKey="OnBoarding">Set OnBoard Training</Dropdown.Item>
                                                    )}
                                                </>
                                            )}
                                            <Dropdown.Item eventKey="AddSubject">Add Module</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        )}
                        <Accordion.Header>
                            <Link disabled={item.disabled} to={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}`}
                                className={`${item.disabled ? 'disabled' : ''}`}>{item.title}
                                {!params.can_edit && item.onboard_training === 1 && (
                                    <i title='Onboard Training' className='fas fa-chalkboard-teacher ml-5'></i>
                                )}
                            </Link>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            {item.sub_cat.map((sub_item, k) => (
                                <Subject
                                    key={'sub' + i + '-' + k}
                                    cat_id={sub_item.cat_id}
                                    index={i}
                                    subindex={k}
                                    content={
                                        <ListGroup key={'list' + sub_item.cat_id} variant="flush">
                                            {sub_item.sub_cat.map((sub, m) => (
                                                <ListGroup.Item
                                                    disabled={sub.disabled}
                                                    key={sub.cat_id}

                                                >
                                                    <div className={`${params.documents == sub.cat_id ? 'active' : ''} d-flex align-items-center justify-content-between ps-2`}>

                                                        <Link
                                                            id={sub.cat_id}
                                                            to={`/${sub.first_step_type == 'quiz' ? 'trainingsreview' : 'trainings'}/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}${sub.first_step_link ? sub.first_step_link : ''}`}>
                                                            {sub.title}
                                                        </Link>

                                                        <div className='d-flex'>
                                                            {params.can_edit ? (

                                                                <div className='document-action d-flex'>

                                                                    {sub.status !== 'Publish' && (
                                                                        <>

                                                                            <OverlayTrigger
                                                                                placement="right"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={<Tooltip id="button-tooltip">This lesson is unpublished</Tooltip>}
                                                                            >

                                                                                <i className='fas fa-info-circle'></i>
                                                                            </OverlayTrigger>
                                                                        </>
                                                                    )}

                                                                    <Dropdown onSelect={(eventKey) => {
                                                                        if (eventKey === 'AddStep') {
                                                                            params.subjectOrDocumentAction('Step', sub.cat_id, sub.title, 'AddStep','','','','','left',sub.cat_id);
                                                                        } else if (eventKey === 'AddStepquiz') {
                                                                            params.subjectOrDocumentAction('Step', sub.cat_id, sub.title, 'AddStepquiz','','','','','left',sub.cat_id);
                                                                        } else {
                                                                            params.subjectOrDocumentAction('Document', sub.cat_id, sub.title, eventKey, sub.training_for, sub.tag_training, '', '', 'left', sub.master_id);
                                                                        }
                                                                    }}>
                                                                        <Dropdown.Toggle variant="link">
                                                                            <i className='fas fa-ellipsis-h'></i>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            {sub.status === 'Publish' ? (
                                                                                <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                                            ) : (
                                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                            )}
                                                                            <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                            <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                            <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                            <Dropdown.Item eventKey="AddStep">Add Training</Dropdown.Item>
                                                                            <Dropdown.Item eventKey="AddStepquiz">Add Quiz</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>

                                                                </div>

                                                            ) : (
                                                                <div className='document-action d-flex'>

                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={<Tooltip id="button-tooltip">{sub.percentage}% completed</Tooltip>}
                                                                    >




                                                                        <div className='dummy-box'></div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            )}
                                                            <button

                                                                disabled={sub.disabled}
                                                                className={`${sub.disabled ? 'disabled' : ''} btn btn-link p-0`}



                                                                onClick={toggleDocOpen('doc' + i + '-' + k + '-' + m)}

                                                            >
                                                                {isDocOpen === 'doc' + i + '-' + k + '-' + m ? (
                                                                    <i className='fas fas fa-angle-up' />
                                                                ) : (
                                                                    <i className='fas fas fa-angle-down' />
                                                                )}
                                                            </button>
                                                        </div>

                                                    </div>

                                                    <Collapse isOpen={isDocOpen == 'doc' + i + '-' + k + '-' + m && is_coach_section === 'No'} className="content-way">
                                                        <ListGroup key={'step_list' + sub.cat_id} className='step-lists content-page-documents-list-item highlight-parent'>
                                                            {sub.sub_steps && sub.sub_steps.map((step, n) => (

                                                                <ListGroup.Item
                                                                    key={step.step_id}
                                                                    className={`${params.step == step.step_id ? 'active' : ''} ${step.disabled ? 'disabled' : ''} highlight`}
                                                                >

                                                                    <div className='d-flex justify-content-between gap-1 AddBorder'>
                                                                        <div className='steps-list d-flex align-items-center'><i className='fas fa-circle'></i>
                                                                            {step.type === 'quiz' ?
                                                                                <Link id={step.step_id} className={`${step.disabled ? 'disabled' : ''}`}
                                                                                    to={`/trainingsreview/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}/${bcrypt(n)}/${bcrypt(step.step_id)}/?step=${bcrypt(step.step_id)}`}>
                                                                                    {step.title}

                                                                                    {step.status === 'Draft' && (
                                                                                        <div className='text-danger'><small>unpublished</small></div>
                                                                                    )}

                                                                                </Link> : (
                                                                                    <>

                                                                                        <Link id={step.step_id} className={`${step.disabled ? 'disabled upcoming-step' : 'upcoming-step'}`}
                                                                                            to={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}/${bcrypt(n)}/${bcrypt(step.step_id)}/?step=${bcrypt(step.step_id)}`}

                                                                                        >
                                                                                            {step.title}


                                                                                            {step.status === 'Draft' && (
                                                                                                <div className='text-danger'><small>unpublished</small></div>
                                                                                            )}
                                                                                        </Link>



                                                                                    </>
                                                                                )}
                                                                        </div>
                                                                        <div className='step-action'>
                                                                            {params.can_edit ? (
                                                                                <Dropdown onSelect={(e) => params.stepMethodAction('Step', step.step_id, step.title, e, step.training_for, step.tag_training, '', '', 'left', step.cat_id)}>
                                                                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                                                        <i className='fas fa-ellipsis-h'></i>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        {step.status === 'Publish' ? (
                                                                                            <>
                                                                                                <Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Retake">Require Retake</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey="Notification">Training Notification</Dropdown.Item>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>
                                                                                        )}
                                                                                        <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                        <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                        <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            ) : uInfo().user_id !== 2 && (
                                                                                <div className='mark-option'>
                                                                                    {step.log_id ? (
                                                                                        <OverlayTrigger
                                                                                            placement="auto"
                                                                                            overlay={<Tooltip id="button-tooltip">You've completed this step</Tooltip>}
                                                                                        >
                                                                                            <i className='fas fa-check-circle'></i>
                                                                                        </OverlayTrigger>
                                                                                    ) : (
                                                                                        <OverlayTrigger
                                                                                            placement="auto"
                                                                                            overlay={<Tooltip id="button-tooltip">You've not completed this step</Tooltip>}
                                                                                        >
                                                                                            <i className='fas fa-circle'></i>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </ListGroup.Item>
                                                            ))}
                                                        </ListGroup>

                                                    </Collapse>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    }
                                    subject={sub_item.title}
                                    isOpen={isOpen === 'sub' + i + '-' + k}
                                    isDocOpen={isDocOpen === 'doc' + i + '-' + k}
                                    toggle={toggleOpen('sub' + i + '-' + k)}
                                    toggleDoc={toggleDocOpen}
                                    status={sub_item.status}
                                    image={sub_item.image}
                                    user_id={sub_item.user_id}
                                    percentage={sub_item.percentage}
                                    training_for={sub_item.training_for}
                                    tag_training={sub_item.tag_training}
                                    master_id={item.cat_id}
                                    disabled={sub_item.disabled}
                                    is_coach_section={is_coach_section}
                                />
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}