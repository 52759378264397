import React, { useContext, useRef, useState, useEffect } from 'react';
import Knob from '../Campaign/knob';
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import Loader from "react-loader";
import { ApiUrl, AttachementsUrl } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import ReactDatatable from "@ashvin27/react-datatable";

export default function TrainingDashboard(props) {
	const [value_prev, setvalue_prev] = useState(0)
	const [completed_steps, setCompletedSteps] = useState([]);
	const [incomplete_documents, setIncompleteDocuments] = useState([]);
	const [progress, setProgress] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [coachesRecords, setCoachesRecords] = useState([]);
	const [records, setRecords] = useState([]);
	const [total_pages, setTotal_pages] = useState(0);


	useEffect(() => {
		fetchData();
		fetchDataCoahes();
	}, []);
	const columns = [
		{ key: 'sr', text: '#', sortable: false },
		{ key: 'step_title', text: 'Title', sortable: false },
		{ key: 'quiz_passing_percentage', text: 'Pass Rate (%)', sortable: false },
		{
			text: "Attempts",
			key: "attempts",
			cell: (record, index) => {
				return (
					record.attempts
				)
			}
		},
		{ key: 'created_at', text: 'Dated', sortable: false },
		{ key: 'score', text: 'score (%)', sortable: false },

		{
			text: "Status",
			key: "quiz_pass_fail",
			cell: (record, index) => {
				if (record.quiz_pass_fail == 'pass') {
					return <p key={index} className="badge bg-success">Pass</p>
				} else if (record.quiz_pass_fail == 'fail') {
					return <p key={index} className="badge bg-danger">Fail</p>
				}
			}
		},

	];
	const config = {
		page_size: 10,
		length_menu: [10, 20, 50, 100],
		show_filter: false,
		show_pagination: false,
		button: {
			excel: false,
			print: false,
			csv: false,
		},
	};
	const fetchData = () => {
		setLoaded(false);
		fetch(`${ApiUrl}` + "get/waybook/coach/dashboard", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ` + uToken(),
			},
			body: JSON.stringify({ user_id: uInfo().user_id }),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === true) {
					setCompletedSteps(response.data.recently_completed_steps);
					setIncompleteDocuments(response.data.incomplete_documents);
					setProgress(response.data.progress);
					setvalue_prev(response.data.progress.overall_percentage);
				}
				setLoaded(true);

			})
			//Then with the error genereted...
			.catch((error) => {
				setLoaded(true);

				console.error("Error:", error);
			});
	};
	const companyName = uInfo().company_name;
	const words = companyName.split(' ');

	//
	const fetchDataCoahes = (queryString = "") => {
		setLoaded(false);
		fetch(`${ApiUrl}` + "get/waybook/coach/dashboard/quizStats" + queryString,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ` + uToken(),
				},
				body: JSON.stringify({
					user_id: uInfo().user_id
				}),
			})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === true) {
					setCoachesRecords(response.data.records)
					setRecords(response.data.records)
					setTotal_pages(response.data.total)
				}
				setLoaded(true);
			})
			//Then with the error genereted...
			.catch((error) => {
				setLoaded(true);
				console.error("Error:", error);
			});
	};
	const tableChangeHandler = (data) => {
		let queryString = Object.keys(data)
			.map((key) => {
				if (key === "sort_order" && data[key
				]) {
					return (
						encodeURIComponent("sort_order") +
						"=" +
						encodeURIComponent(data[key
						].order) +
						"&" +
						encodeURIComponent("sort_column") +
						"=" +
						encodeURIComponent(data[key
						].column)
					);
				} else {
					return encodeURIComponent(key) + "=" + encodeURIComponent(data[key
					]);
				}
			})
			.join("&");
		fetchData("?" + queryString, this.state.filter);
	};


	return (

		<>
			<Loader loaded={loaded}></Loader>
			<div className='default-layout'>
				<div className="dash-welcome-left">
					<div className='row'>
						<div className='col-lg-12'>
							<div className='TraningRightContent DashboardCard'>
								<div className="row">
									<div className="col-lg-12">
										<div className='DashboardHeading mb-3 text-center'>
											<h3>Good Afternoon {uInfo().first_name}! Welcome to <span className='PrimaryClass'>PROFIT</span><span className='SecondryClass'>COACH</span> Business Center™</h3>
										</div>
									</div>
									{/* Start Coach Dashboard */}
									<div className='col-lg-4 pe-2'>
										<div class="card mt-3">
											<div class="card-body ps-2 pb-1 pe-1 DashBordTop">
												<div class="DashboardHeadingSection mb-4"><h4 class="card-title">Recently Viewed</h4></div>
												<ul className='RecentlyViewed'>
													{completed_steps && completed_steps.map((step, n) => (
														<li className='d-flex align-items-start'>
															<span className='ID-Number'>{n + 1}</span>
															<div className='ms-2'>
																<h6><Link to={step.link} style={{ color: 'inherit' }}>{step.step_title}</Link></h6>
																<p>{step.cat_title}</p>
																<p><span><i className="mdi mdi-chevron-right"></i>
																</span>{step.subject_title}</p>

																<p><span><i className="mdi mdi-chevron-right"></i></span>{step.document_title}</p>
															</div>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
									<div className='col-lg-4 px-2'>
										<div class="card mt-3">
											<div class="card-body ps-2 pe-1 pb-1 DashBordTop">
												<div class="DashboardHeadingSection mb-4"><h4 class="card-title">Incomplete Training’s</h4></div>
												<ul className='IncompleteTraining'>
													{/* <li className='d-flex align-items-center justify-content-between'>
													<div className='d-flex align-items-center'>
														<span className='ID-Number'>1</span>
														<div className='ms-2'>
															<h6>Document Name</h6>
															<p>Course<span></span>Training Module<span></span>Document</p>
														</div>
													</div>
													<div className='PercentageComplete'>50%</div>
												</li> */}

													{incomplete_documents && incomplete_documents.map((doc, n) => (
														<li className='d-flex align-items-center justify-content-between'>
															<div className='d-flex align-items-start'>
																<span className='ID-Number'>{n + 1}</span>
																<div className='ms-2'>
																	<h6><Link to={doc.link} style={{ color: 'inherit' }}>{doc.document_title}</Link></h6>
																	<p>{doc.cat_title}</p><p><span><i className="mdi mdi-chevron-right"></i></span>{doc.subject_title}</p>
																</div>
															</div>
															<div className='PercentageComplete'>{doc.percentage}%</div>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
									<div className='col-lg-4 ps-2'>
										<div class="card mt-3">
											<div class="card-body px-2 pb-1 DashBordTop">
												<div class="DashboardHeadingSection mb-4"><h4 class="card-title">Your Progress</h4></div>
												<div className='d-flex justify-content-center'>
													<Knob className="ProgressKnob"
														value={value_prev}
														height={150}
														width={150}
														fgColor="#008037"
													/>
												</div>
												<div className='row justify-content-center mt-3'>
													<div className='col-md-6 pe-1 ps-4'>
														<div className='SetpsQuestions'>
															<ul>
																<li>Total Topics: <span><b>{progress?.total_steps ?? '0'}</b></span></li>
																<li>Completed Topics: <span><b>{progress?.total_completed_steps ?? '0'}</b></span></li>
																<li>Remaining Topics: <span><b>{progress?.remaining_steps ?? '0'}</b></span></li>
															</ul>
														</div>
													</div>
													<div className='col-md-6 ps-1 pe-4'>
														<div className='SetpsQuestions'>
															<ul>
																<li>Total Quiz: <span><b>{progress?.total_quiz ?? '0'}</b></span></li>
																<li>Completed Quiz: <span><b>{progress?.total_completed_quiz ?? '0'}</b></span></li>
																<li>Remaining Quiz: <span><b>{progress?.remaining_quiz ?? '0'}</b></span></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-12'>
										<div class="card mt-3 mb-0">
											<div class="card-body px-2 pb-1">
												<div class="DashboardHeadingSection mb-4"><h4 class="card-title">Quiz Stats</h4></div>
												<div class="row table-body asrt-table-body mt-2">
													<div class="col-md-12">
														<div className="tableFixHead">
															<ReactDatatable
																config={config}
																records={records}
																columns={columns}
																dynamic={true}
																total_record={total_pages}
																pagination={false}
																onChange={tableChangeHandler}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* End Coach Dashboard */}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
