import React from 'react';
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import "suneditor/dist/css/suneditor.min.css";
import { useHistory, useLocation } from 'react-router-dom';
function matchCurrentURL(givenURL) {
    const currentURL = window.location.href;
    const urlObj = new URL(currentURL);
    const queryString = urlObj.search;
    const path = urlObj.pathname + queryString;
    return path === givenURL;
}
const UrlNextPrev = (props) => {
   
    const history = useHistory();
    const is_coach_section = props.is_coach_section ? props.is_coach_section : "No";
    const queryParam1 = is_coach_section === "Yes" ? '&sec=Y29hY2g=' : '';
    const givenURL = props.link + bcrypt(props.nvalue) + '/' + bcrypt(props.docstep[props.nvalue].step_id) + '/?step=' + bcrypt(props.docstep[props.nvalue].step_id) + queryParam1;
    const { search } = useLocation();
    const url_params = new URLSearchParams(search);



    let prevurl = "";
    let prevkey = props.nvalue;

    let nexturl = "";
    let nextkey = props.nvalue;


    if (matchCurrentURL(givenURL)) {
        if (prevkey > 0) {
            prevkey = prevkey - 1;
            prevurl = props.link + bcrypt(prevkey) + '/' + bcrypt(props.docstep[prevkey].step_id) + '/?step=' + bcrypt(props.docstep[prevkey].step_id);
      
            if (props.docstep[prevkey].type == 'quiz') {
                if (typeof prevurl === 'string') {
                    prevurl = prevurl.replace('trainings/', 'trainingsreview/');
                }
            }
            if (props.docstep[prevkey].type == 'content') {
                if (typeof prevurl === 'string') {
                    prevurl = prevurl.replace('trainingsreview/', 'trainings/');
                }
            }
        }

        if (typeof props.docstep[nextkey + 1] !== 'undefined') {
            nextkey = nextkey + 1;
            nexturl = props.link + bcrypt(nextkey) + '/' + bcrypt(props.docstep[nextkey].step_id) + '/?step=' + bcrypt(props.docstep[nextkey].step_id);
            if (props.docstep[nextkey].type == 'quiz') {
                if (typeof nexturl === 'string') {
                    nexturl = nexturl.replace('trainings/', 'trainingsreview/');
                }
            }
            if (props.docstep[nextkey].type == 'content') {
                if (typeof nexturl === 'string') {
                    nexturl = nexturl.replace('trainingsreview/', 'trainings/');
                }
            }
        }


        const linkUrl = (link) => {
            //console.log(prevurl, 'linklinklink')
            if (link == "back") {
                history.push(prevurl);
            } else if (link == "forward") {
                history.push(nexturl);
            }
        }
        return (
            <>
                <div className='NextBackBtn edit-step-action'>
                    {
                        uInfo().type && (uInfo().type === "WlAdmin") ? (
                            <>
                            {!prevurl ? (
                                    <button className='BackBtn btn btn-primary disabled btn-sm' onClick={() => linkUrl("back")}>Back</button>
                                ) : (
                                    <button className='BackBtn btn btn-primary btn-sm' onClick={() => linkUrl("back")}>Back</button>
                                )}
                                
                                
                                {!nexturl ? (
                                    <button className='BackBtn bwaves-effect waves-light btn btn-secondary ms-2 disabled btn-sm' onClick={() => linkUrl("forward")}>
                                        Next
                                    </button>
                                ) : (
                                    <button className='BackBtn bwaves-effect waves-light btn btn-secondary ms-2 btn-sm' onClick={() => linkUrl("forward")}>
                                        Next
                                    </button>
                                )}
                            </>

                        ) : (
                            <>

                                {
                                    props.nvalue > 0 ? (
                                        <button className='BackBtn btn btn-primary btn-sm' onClick={() => linkUrl("back")}>Back</button>
                                    ) : (
                                        <button className='BackBtn btn btn-primary disabled btn-sm' onClick={() => linkUrl("back")}>Back</button>
                                    )
                                }

                                {
                                    props.stepCount == props.nvalue + 1 ? (
                                        <button className='BackBtn NextBtn bwaves-effect waves-light btn btn-secondary ms-2 disabled btn-sm' onClick={() => linkUrl("forward")}>Next</button>
                                    ) : props.list.log_id > 0 ? (
                                        <button className='BackBtn NextBtn bwaves-effect waves-light btn btn-secondary ms-2 btn-sm' onClick={() => linkUrl("forward")}>Next</button>
                                    ) : (
                                        <button className='BackBtn NextBtn bwaves-effect waves-light btn btn-secondary ms-2 disabled btn-sm' onClick={() => linkUrl("forward")}>Next</button>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </>
        );
    } else {
        return (
            ""
        );
    }
}

export default UrlNextPrev;
