import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AdminTagGroup from "./AdminTagGroup";

export default class CoachTagList extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');
        this.state = {
            id: id ? dcrypt(id) : null,
            records: [],
            my_tags: [],
            showEditButton: false,
            is_add_tag: false,
            defaultActiveKey: "users",
            record: {},
            trainings: [],
            associated_trainings: [],
            users: [],
            associated_users: [],
            roles: [],
            total_pages: 0,
            modal_1: false,
            extTime: false,
            tag_title: '',
            editMode: false,
            filter: {},
            filter_user: {},
            page_name: " Training Tags List",
            customDate: true,
            modal_2: false,
            selectedIds: [],
            selectAll: false,
            selectedIdsAssociated: [],
            selectAllAssociated: false,
            selectedUserIds: [],
            selectAllUsers: false,
            selectedUserIdsAssociated: [],
            selectAllAssociatedUserIds: false,
            modal_3: false,
            input_field_users: "",
            input_field_associated_users: "",
            current_assoc_user_id: "",

            categories: [],
            subjects: [],
            show_subject: false,
            documents: [],
            show_document: false,
            show_associated_subject: false,
            show_associated_document: false,
            input_field_trainings: "",
            input_field_associated_trainings: "",
        };

        this.columns = [
            {
                text: "#",
                key: "id",
                sortable: false,
            },
            {
                text: "Title",
                key: "name",
                sortable: false,
                cell: (record, index) => {
                    return (
                        <>
                            {record.name}
                        </>
                    );
                }
            },

            {
                text: "Associated Training(s)",
                key: "trainings_name",
                cell: (record, index) => {
                    const trainingsName = record.trainings_name || "";
                    const shortTrainingsName = trainingsName.length > 60 ? trainingsName.substring(0, 60) + "..." : trainingsName;
                    return <span title={trainingsName}>{shortTrainingsName}</span>;
                }
            },
            {
                text: "Total Coach Partners",
                key: "total_coach_partner",
                sortable: false,
            },
            {
                text: "Total Clients",
                key: "total_client",
                sortable: false,
            },
            {
                text: "Added By",
                key: "tag_type",
                sortable: false,
                cell: (record, index) => {
                    if (record.tag_type === 'my_tag') {
                        return (
                            <>
                                <span className="badge bg-success">ME</span>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <span className="badge bg-info">ADMIN</span>
                            </>
                        );
                    }
                }
            },
            {
                text: "Status",
                key: "status",
                cell: (record, index) => {

                    if (record.status == 1) {
                        return <span className="badge bg-success">{"Active"}</span>
                    } else if (record.status == '0') {
                        return <span
                            className="badge bg-danger"
                        >{'Inactive'}</span>
                    } else {
                        return <span className="badge bg-warning">{'Inactive'}</span>
                    }

                }
            },
            {
                text: "Created At",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {isAllowed(uInfo(), ["can_edit"]) && (
                                <>
                                    <button
                                        className="btn btn-success btn-sm mr-5"
                                        onClick={() => {
                                            this.getData();
                                        }
                                        }
                                    >
                                        <i className="mdi mdi-refresh"></i>
                                    </button>

                                    <button
                                        onClick={() => {
                                            this.setState({
                                                modal_2: true,
                                                record: record,
                                            });
                                            this.getmeta(record.id);

                                        }
                                        }
                                        className="btn btn-primary btn-sm mr-5"
                                    >
                                        <i className="mdi mdi-pencil"></i>
                                    </button>
                                    {record.tag_type === 'my_tag' && (
                                        <>
                                            <button
                                                className="btn btn-danger btn-sm mr-5"
                                                onClick={this.deleteRecord.bind(this, record, index)}
                                            >
                                                <i className="mdi mdi-trash-can-outline"></i>
                                            </button>

                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => this.props.history.push("/training/tagdetails/" + bcrypt(record.id))}
                                            >
                                                <i className="fas fa-eye"></i>
                                            </button>
                                        </>
                                    )}

                                </>
                            )}
                        </Fragment>

                    );
                },
            },
        ];
        this.config = {
            key_column: "sr",
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };

    }





    componentDidMount() {
        this.getData();

        if (this.state.id && !this.state.modal_2) {
            this.setState({
                modal_2: true,
                record: {}
            });
        }


        if (this.state.id && !this.state.modal_3) {
            // this.setState({
            //     modal_3: true,
            //     record: {}
            // });
        }

        this.state.id && this.getmeta(this.state.id);

    }
    handleSelect = (key) => {
        if (key === 'users') {
            this.fetchRecord("", "users");
            this.getAssociatedRecord(this.state.id, "", "users");
        }
        if (key === 'trainings') {
            this.fetchRecord("", "trainings");
            this.getAssociatedRecord(this.state.id, "", "trainings");
        }
        this.setState({ defaultActiveKey: key });
    };
    handleActionChange = async (key, value, type) => {

        let api_uri = ApiUrl + "get/users";
        let data = [];
        if (type === 'users') {
            this.setState({ show_user: value !== '' ? true : false });
        }


        if (type === 'associated_users') {
            api_uri = ApiUrl + "get/users";
            let obj1 = { [key]: value };
            let obj2 = { id: this.state.id };
            let data = [obj1, obj2];
            this.setState({ show_associated_user: value !== '' ? true : false });
        }
        if (type === 'categories') {
            api_uri = ApiUrl + "get/waybook/category";
            let data = [{ can_edit: false }];


        }
        if (type === 'subjects') {
            api_uri = ApiUrl + "get/waybook/subcategory/" + value;
            let data = [{ can_edit: false }];
            this.setState({
                show_subject: value !== '' ? true : false,
                current_cat_id: value,
            });

            if (value === '') {
                this.setState({
                    show_subject: false,
                    show_document: false,
                });
                return false;
            }
        }
        if (type === 'documents') {
            api_uri = ApiUrl + "get/waybook/subjects/" + value;
            this.setState({ show_document: value !== '' ? true : false, current_subject_id: value });
            let data = [{ can_edit: false }];
            if (value === '') {
                return false;
            }
        }

        if (type === 'associated_subjects') {
            api_uri = ApiUrl + "get/waybook/subcategory/" + value;
            let data = [{ can_edit: false }];
            this.setState({ show_associated_subject: value !== '' ? true : false, current_assoc_cat_id: value });
            if (value === '') {
                this.setState({ show_associated_subject: false, show_associated_document: false });
                return false;
            }
        }
        if (type === 'associated_documents') {
            api_uri = ApiUrl + "get/waybook/subjects/" + value;
            this.setState({ show_associated_document: value !== '' ? true : false, current_subject_id: value });
            let data = [{ can_edit: false }];
            if (value === '') {
                return false;
            }
        }

        let queryString = data.map(obj => {
            return Object.keys(obj).map(key => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
            }).join("&");

        }).join("&");
        console.log(queryString, 'queryString')
        fetch(`${api_uri}` + "?" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),

            },
            body: JSON.stringify({
                value: value,
                is_coach_section: "Yes",
            }),

        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    if (type == 'users') {
                        this.setState({
                            selected_users: response.data.records,
                        })
                    }
                    if (type == 'associated_users') {
                        this.setState({
                            selected_associated_users: response.data.records,
                        })
                    }
                    if (type == 'categories') {
                        this.setState({
                            categories: response.data.record,
                        })
                    }
                    if (type == 'subjects' || type == 'associated_subjects') {
                        this.setState({
                            subjects: response.data.records,
                        })
                    }
                    if (type == 'documents' || type == 'associated_documents') {
                        this.setState({
                            documents: response.data.records,
                        })
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });





    };
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + 'delete/trainingtags', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ` + uToken()
                        },
                        body: JSON.stringify({ 'id': record.id }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            });

    }
    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData(this.props.match.params.type, values);
    };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/trainingtags?login_user_type=coach" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),

        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getAdminTagsLists = async (my_id) => {
        fetch(`${ApiUrl}` + "get/user/trainingtags", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        my_tags: response.data.records,
                    });
                    const isRecordTag = response.data.records && response.data.records.some(row => row.id === my_id);
                    this.setState({
                        showEditButton: isRecordTag
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getmeta = (id) => {

        fetch(`${ApiUrl}` + "get/training/meta", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id }),

        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        id: response.data.record.id,
                        edit_tag_id: response.data.record.id,
                    });
                    this.fetchRecord("", "users");
                    this.getAssociatedRecord(response.data.record.id, "", "users");
                    this.fetchRecord("", "trainings");
                    this.getAssociatedRecord(response.data.record.id, "", "trainings");

                    this.getAdminTagsLists(response.data.record.id);
                    this.handleActionChange('', '', 'categories');
                    this.setState((prevState) => ({
                        record: {
                            ...prevState.record,
                            name: response.data.record.name,
                        }
                    }));
                    this.setState({
                        tag_title: response.data.record.name,
                    });

                } else {
                    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    fetchRecord = (queryString = "", type = "users") => {
        fetch(`${ApiUrl}` + `get/${type}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id, login_user_type: 'coach' }),

        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {

                    if (type === 'users') {
                        this.setState({
                            users: response.data.records,
                        });
                    }
                    if (type === 'trainings') {
                        this.setState({
                            trainings: response.data.records,
                        });
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getAssociatedRecord = (id = null, queryString = "", type = "trainings") => {
        fetch(`${ApiUrl}` + `get/associated/${type}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id, login_user_type: "coach" }),

        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    if (type === 'users') {
                        this.setState({
                            associated_users: response.data.records,
                        });
                    }
                    if (type === 'trainings') {
                        this.setState({
                            associated_trainings: response.data.records,
                        });
                    }

                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilter = async (event, data) => {
        let type = (data.type) ? (data.type) : "trainings";

        let queryString = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }).join("&");
        this.fetchRecord("?" + queryString, type);
    };
    handleTagChange = async (e) => {
        const value = e.target.value;
        this.setState({
            tag_title: value,
        });

    };
    handleButtonClick = (type) => {
        if (this.state.extTime) {
            this.setState({
                extTime: false,
                is_add_tag: false,
            });
            return false;
        }
        this.setState({
            extTime: true,
            editMode: type === 'edit',
        });

        if (type === 'edit') {
            this.setState((prevState) => ({
                record: {
                    ...prevState.record,
                    name: this.state.record.name,
                }
            }));
            this.setState({
                tag_title: this.state.record.name,
            });
        } else {
            console.log('ddddddddddddddd')
            this.setState({
                tag_title: '',
                is_add_tag: true,
            });
        }
    };
    handleCreateTag = (event) => {
        fetch(`${ApiUrl}` + "add/training/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ name: this.state.tag_title }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    const newTag = response.data.record;

                    this.setState(prevState => ({
                        records: [...prevState.records, newTag]
                    }));
                    this.setState({
                        tag_title: '',
                        extTime: false,
                    });

                    swal({
                        title: "You created a new training tag",
                        text: "Do you want to manage users for it?",
                        icon: "success",
                        buttons: {
                            cancel: "No",
                            confirm: "Yes", // Change "Yes" text
                        },
                        dangerMode: true,
                    })
                        .then((confirmation) => {
                            if (confirmation) {
                                this.setState({
                                    modal_2: true,
                                    record: newTag,
                                });
                                this.getmeta(newTag.id);
                            }
                        });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });



    };

    handleUpdateTag = (event) => {
        fetch(`${ApiUrl}` + "add/training/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: this.state.edit_tag_id,
                name: this.state.tag_title,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    const updatedTag = response.data.record;

                    this.setState(prevState => ({
                        records: prevState.records.map(tag =>
                            tag.id === updatedTag.id ? updatedTag : tag
                        ),
                        tag_title: '',
                        extTime: false,
                        editMode: false,
                    }));

                    swal({
                        title: "Tag updated successfully",
                        text: "Do you want to manage users for it?",
                        icon: "success",
                        buttons: {
                            cancel: "No",
                            confirm: "Yes",
                        },
                        dangerMode: true,
                    })
                        .then((confirmation) => {
                            if (confirmation) {
                                this.setState({
                                    modal_2: true,
                                    record: updatedTag,
                                });
                                this.getmeta(updatedTag.id);
                            }
                        });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    handleFilterAssociated = async (event, data) => {
        let type = (data.type) ? (data.type) : "trainings";
        let queryString = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }).join("&");
        this.getAssociatedRecord(this.state.id, "?" + queryString, type);
    };
    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    searchFilter = () => {

    }

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("&" + queryString, this.state.filter);
    };




    handleSubmit = async (event, values) => {
        fetch(`${ApiUrl}` + "add/training/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        modal_1: false,
                    });
                    this.getData();

                    this.getmeta(response.data.record.id);
                    this.setState({
                        modal_2: true,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSelectAll = (event, type) => {
        const isChecked = event.target.checked;
        if (['associated_trainings', 'associated_users'].includes(type)) {
            if (type == 'associated_users') {
                this.setState({
                    selectAllAssociatedUserIds: isChecked,
                    selectedUserIdsAssociated: isChecked
                        ? this.state.associated_users.map(training => training.id)
                        : [],
                    selectAllUsers: false,
                    selectedUserIds: [],
                });
            } else {
                this.setState({
                    selectAllAssociated: isChecked,
                    selectedIdsAssociated: isChecked
                        ? this.state.associated_trainings.map(user => user.id)
                        : [],
                    selectAll: false,
                    selectedIds: [],
                });
            }
        } else {
            if (type == 'users') {
                this.setState({
                    selectAllUsers: isChecked,
                    selectedUserIds: isChecked
                        ? this.state.users.map(user => user.id)
                        : [],
                    selectAllAssociatedUserIds: false,
                    selectedUserIdsAssociated: [],
                });
            } else {
                this.setState({
                    selectAll: isChecked,
                    selectedIds: isChecked
                        ? this.state.trainings.map(training => training.id)
                        : [],
                    selectAllAssociated: false,
                    selectedIdsAssociated: [],
                });
            }

        }
    };

    handleCheckboxChange = (id, type) => {
        if (['associated_trainings', 'associated_users'].includes(type)) {
            if (type == 'associated_users') {
                this.setState(prevState => {
                    const { selectedUserIdsAssociated } = prevState;
                    const newSelectedIds = selectedUserIdsAssociated.includes(id)
                        ? selectedUserIdsAssociated.filter(selectedId => selectedId !== id)
                        : [...selectedUserIdsAssociated, id];
                    return {
                        selectedUserIdsAssociated: newSelectedIds,
                        selectAllAssociatedUserIds: newSelectedIds.length === this.state.associated_users.length,
                    };
                });
            } else {
                this.setState(prevState => {
                    const { selectedIdsAssociated } = prevState;
                    const newSelectedIds = selectedIdsAssociated.includes(id)
                        ? selectedIdsAssociated.filter(selectedId => selectedId !== id)
                        : [...selectedIdsAssociated, id];
                    return {
                        selectedIdsAssociated: newSelectedIds,
                        selectAllAssociated: newSelectedIds.length === this.state.associated_trainings.length,
                    };
                });
            }
        } else {
            if (type == 'users') {
                this.setState(prevState => {
                    const { selectedUserIds } = prevState;
                    const newSelectedIds = selectedUserIds.includes(id)
                        ? selectedUserIds.filter(selectedId => selectedId !== id)
                        : [...selectedUserIds, id];
                    return {
                        selectedUserIds: newSelectedIds,
                        selectAllUsers: newSelectedIds.length === this.state.users.length,
                    };
                });
            } else {
                this.setState(prevState => {
                    const { selectedIds } = prevState;
                    const newSelectedIds = selectedIds.includes(id)
                        ? selectedIds.filter(selectedId => selectedId !== id)
                        : [...selectedIds, id];
                    return {
                        selectedIds: newSelectedIds,
                        selectAll: newSelectedIds.length === this.state.trainings.length,
                    };
                });
            }
        }
    };
    handleLinkClick = (action_type) => {
        const formData = new FormData();
        formData.append('tag_id', this.state.id);
        let ids;

        switch (action_type) {
            case 'associated_users':
                ids = this.state.selectedUserIdsAssociated;
                break;
            case 'users':
                ids = this.state.selectedUserIds;
                break;
            case 'associated_trainings':
                ids = this.state.selectedIdsAssociated;
                break;
            default:
                ids = this.state.selectedIds;
        }

        formData.append('ids', ids);
        formData.append('action_type', action_type);
        formData.append('login_user_type', 'coach');
        fetch(`${ApiUrl}` + `handle/trainingswitch/action`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    if (['trainings', 'associated_trainings'].includes(action_type)) {

                        this.setState({
                            selectAllAssociated: false,
                            selectedIdsAssociated: [],
                            selectAll: false,
                            selectedIds: [],
                        });
                        if (action_type == 'trainings') {
                            if (this.state.show_document) {
                                this.setState({
                                    show_associated_subject: true,
                                    show_associated_document: true,
                                    current_assoc_cat_id: this.state.current_cat_id,
                                    current_assoc_subject_id: this.state.current_subject_id,
                                    current_assoc_document_id: this.state.current_document_id,
                                });

                                let params_array = [
                                    { type: "trainings" },
                                    { cat_id: this.state.current_cat_id },
                                    { subject_id: this.state.current_subject_id },
                                    { document_id: this.state.current_document_id }
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                }).join("&");

                                this.getAssociatedRecord(this.state.id, "?" + queryString, "trainings");
                                this.fetchRecord("?" + queryString, "trainings");
                            } else {
                                this.getAssociatedRecord(this.state.id, "", "trainings");
                                this.fetchRecord("", "trainings");
                            }
                        }
                        if (action_type == 'associated_trainings') {
                            if (this.state.current_assoc_document_id) {
                                this.setState({
                                    show_subject: true,
                                    show_document: true,
                                    current_cat_id: this.state.current_assoc_cat_id,
                                    current_subject_id: this.state.current_assoc_subject_id,
                                    current_document_id: this.state.current_assoc_document_id,
                                });

                                let params_array = [
                                    { type: "trainings" },
                                    { cat_id: this.state.current_assoc_cat_id },
                                    { subject_id: this.state.current_assoc_subject_id },
                                    { document_id: this.state.current_assoc_document_id }
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                }).join("&");

                                this.fetchRecord("?" + queryString, "trainings");
                                this.getAssociatedRecord(this.state.id, "?" + queryString, "trainings");
                            } else {
                                this.getAssociatedRecord(this.state.id, "", "trainings");
                                this.fetchRecord("", "trainings");
                            }
                        }

                    }
                    if (['users', 'associated_users'].includes(action_type)) {
                        this.fetchRecord("", "users");
                        this.getAssociatedRecord(this.state.id, "", "users");
                        this.setState({
                            selectAllAssociatedUserIds: false,
                            selectedUserIdsAssociated: [],
                            selectAllUsers: false,
                            selectedUserIds: [],
                        });
                    }
                } else {
                    swal({
                        text: response.message,
                        icon: "warning",
                    });
                    return false;
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    resetFilterOtherTrainining = async (action_type) => {
        switch (action_type) {
            case 'associated_users':
                this.setState({
                    show_associated_user: false,
                    current_assoc_user_id: '',
                    current_assoc_user_type: '',
                    input_field_associated_users: ''
                });
                this.getAssociatedRecord(this.state.id, "", "users");
                break;
            case 'users':
                this.setState({
                    show_user: false,
                    current_user_id: '',
                    current_user_type: '',
                    input_field_users: ''
                });
                this.fetchRecord("", "users");
                break;
            case 'associated_trainings':
                this.setState({
                    show_associated_subject: false,
                    show_associated_document: false,
                    current_assoc_cat_id: this.state.current_cat_id,
                    current_assoc_subject_id: '',
                    current_assoc_document_id: '',
                    input_field_associated_trainings: ''
                });
                this.getAssociatedRecord(this.state.id, "", "trainings");
                break;
            default:
                this.setState({
                    show_subject: false,
                    show_document: false,
                    current_cat_id: this.state.current_cat_id,
                    current_subject_id: '',
                    current_document_id: '',
                    input_field_trainings: ''
                });
                this.fetchRecord("", "trainings");
        }
    };
    handleSave = (e) => {
        e.preventDefault();
        if (this.state.editMode) {
            this.handleUpdateTag(e);
        } else {
            this.handleCreateTag(e);
        }
    }
    render() {
        return (
            <ErrorBoundary>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6} md={6}>
                        <div className="page-title-box text-align-right">
                            <button
                                onClick={() => {
                                    this.setState({
                                        modal_3: true,
                                        record: {
                                            type: 'text'
                                        }
                                    });
                                    //   this.getmeta(null);
                                }
                                }
                                className="btn btn-success mr-3"
                            >
                                Manage Training Groups
                            </button>
                            {uInfo().coach_can_sell_trainings === 'active_paid' && (
                                <button
                                    onClick={() => {
                                        this.setState({
                                            modal_2: true,
                                            record: {
                                                type: 'text'
                                            }
                                        });
                                        this.getmeta(null);
                                    }
                                    }
                                    className="btn btn-success mr-3"
                                >
                                    Manage Training Tags
                                </button>
                            )}
                            {uInfo().coach_can_sell_trainings === 'active_paid' && (
                                <button
                                    onClick={() =>
                                        this.setState({
                                            modal_1: true,
                                            record: {
                                                type: 'text'
                                            }
                                        })
                                    }
                                    className="btn btn-info"
                                >
                                    <i className="mdi mdi-plus"> </i> Create
                                </button>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody className="pb-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="date_range"
                                                    label="Duration"
                                                    onChange={(e) =>
                                                        e.target.value == "Custom Date"
                                                            ? this.setState({
                                                                customDate: false,
                                                            })
                                                            : this.setState({
                                                                customDate: true,
                                                            })
                                                    }
                                                >
                                                    <option value=""> All Time </option>
                                                    <option value="Current Week">
                                                        Current Week{" "}
                                                    </option>
                                                    <option value="Last Week"> Last Week </option>
                                                    <option value="Current Month">
                                                        Current Month{" "}
                                                    </option>
                                                    <option value="Last Month"> Last Month </option>
                                                    <option value="Last 3 Months">
                                                        Last 3 Months{" "}
                                                    </option>
                                                    <option value="Last 6 Months">
                                                        Last 6 Months{" "}
                                                    </option>
                                                    <option value="Current Year">
                                                        Current Year{" "}
                                                    </option>
                                                    <option value="Last Year"> Last Year </option>
                                                    <option value="Custom Date"> Custom Date </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="start_date"
                                                    label="Start date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="end_date"
                                                    label="End date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="status"
                                                    label="Status"
                                                    id="s"
                                                >
                                                    <option value={""}> Select Status </option>
                                                    <option value={"1"}> Active </option>
                                                    <option value={"0"}> Inactive </option>
                                                </AvField>
                                            </div>
                                        </Col>

                                        <Col md="4">
                                            <div className="mb-0">
                                                <label className=""> {"Action"}</label>
                                                <div className="button-items">
                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                <ReactDatatable
                                    id={''}
                                    key={this.state.id}
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.modal_1}
                    role="dialog"
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            modal_1: !this.state.modal_1,
                            record: {}
                        });
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    modal_1: !this.state.modal_1,
                                    record: {}
                                });
                            }}
                            className="text-transform-capitalize p-2"
                        >
                            {'Create Training Tags'}
                        </ModalHeader>
                        <AvForm
                            onValidSubmit={this.handleSubmit}
                            className="needs-validation"
                        >
                            <ModalBody>
                                {
                                    !this.state.record.question_id && (
                                        <div className="mb-3 col-lg-12">
                                            <AvField
                                                name="name"
                                                label="Name"
                                                value={this.state.record.name}
                                                className="form-control"
                                                placeholder="Enter Tag Name"
                                                required
                                            />
                                        </div>
                                    )
                                }
                            </ModalBody>
                            <ModalFooter className="TagsFooter justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({
                                            modal_1: !this.state.modal_1,
                                            record: {}
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Create
                                </button>


                            </ModalFooter>
                        </AvForm>
                    </div>
                </Modal>



                {/* modal 2 start */}
                <Modal
                    isOpen={this.state.modal_2}
                    role="dialog"
                    centered={true}
                    className="exampleModal TagsPopup"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            modal_2: !this.state.modal_2,
                            record: {}
                        });
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    modal_2: !this.state.modal_2,
                                    record: {}
                                });
                            }}
                            className="text-transform-capitalize"
                        >
                            {'Manage Training Tags'}
                        </ModalHeader>


                        <ModalBody>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                className="needs-validation">

                                <div className="row mx-0 align-items-center">
                                    {uInfo().coach_can_sell_trainings === 'active_paid' && (
                                        <div class="mb-0 col-lg-12 ps-0 mt-1">
                                            <button
                                                type='button'
                                                title="Add new tag"
                                                className='btn btn-success btn-sm pull-right'
                                                onClick={() => {
                                                    this.handleButtonClick('plus');
                                                }}
                                            >
                                                <i className='fas fa-plus' />
                                            </button>
                                        </div>
                                    )}

                                    <div className="mb-0 col-lg-5 ps-0 d-flex align-items-end">
                                        <div className="flex-grow-1 mt-3">
                                            <AvField
                                                className="select form-control"
                                                type="select"
                                                name="tag_id"
                                                value={this.state.record && this.state.record.id}
                                                required
                                                onChange={(e) => {
                                                    const selectedTagId = parseInt(e.target.value, 10);
                                                    const isRecordTag = this.state.my_tags.some(row => row.id === selectedTagId);

                                                    this.setState({
                                                        edit_tag_id: selectedTagId,
                                                        showEditButton: isRecordTag
                                                    }, () => {
                                                        // Using a callback to ensure the state has been updated before making additional updates
                                                        if (!isRecordTag) {
                                                            this.setState({ defaultActiveKey: "users" });
                                                        }
                                                    });

                                                    this.getmeta(selectedTagId);
                                                }}



                                            >
                                                <optgroup label="My Tags">
                                                    {this.state.my_tags.map((row, i) => (
                                                        <option key={i} value={row.id}>{row.name}</option>
                                                    ))}
                                                </optgroup>
                                                <optgroup label="Global Tags">
                                                    {this.state.records
                                                        .filter(record => !this.state.my_tags.some(tag => tag.id === record.id)) // Skip if the record exists in my_tags
                                                        .map((row, i) => (
                                                            <option key={i} value={row.id}>{row.name}</option>
                                                        ))
                                                    }
                                                </optgroup>

                                            </AvField>
                                        </div>

                                        {this.state.showEditButton && (
                                            <a
                                                type='button'
                                                className='btn btn-primary btn-sm mb-1 ml-5'
                                                onClick={() => this.handleButtonClick('edit')}
                                            >
                                                <i className="fas fa-edit" />
                                            </a>
                                        )}
                                    </div>


                                    {((this.state.extTime && this.state.showEditButton) || this.state.is_add_tag) && (
                                        <>
                                            <div className="col-lg-3">
                                                <label className="w-100"></label>
                                                <input
                                                    onChange={(e) => this.handleTagChange(e)}
                                                    type="text"
                                                    value={this.state.tag_title}

                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <label className="w-100"></label>
                                                <button
                                                    type='button'
                                                    className='btn btn-success btn-sm'
                                                    onClick={this.handleSave}
                                                >
                                                    {this.state.editMode ? 'Update' : 'Save'}
                                                </button>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </AvForm>
                            <Tabs defaultActiveKey={this.state.defaultActiveKey} id="uncontrolled-tab-example" className="mb-0 px-0 mx-0" onSelect={this.handleSelect}>
                                <Tab eventKey="users" title="Users" className="pt-3">
                                    <div className="row">
                                        <div className="col-lg-6 ps-4">
                                            <AvForm
                                                onValidSubmit={this.handleFilterAssociated}
                                                ref={(c) => (this.form_user = c)}
                                                className="needs-validation"
                                            >
                                                <AvField
                                                    type="hidden"
                                                    name="type"
                                                    value={"users"}
                                                />
                                                <label>Associated Users</label>
                                                <div className="row mx-0 align-items-center">
                                                    <label className="col-lg-2 m-0" style={{ paddingLeft: 0, paddingRight: 0 }}>Filter By</label>
                                                    <div className="col-lg-5 ps-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="user_type"
                                                            value={this.state.current_assoc_user_id}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                this.setState({
                                                                    current_assoc_user_id: value
                                                                });
                                                            }}
                                                        >
                                                            <option value={""}>All</option>
                                                            <option value={"CoachPartner"}>CoachPartner</option>
                                                            <option value={"Client"}>Client</option>
                                                        </AvField>
                                                    </div>
                                                    <div className="col-lg-5 px-0">
                                                        <AvField
                                                            name="user_input"
                                                            label=""
                                                            className="form-control"
                                                            placeholder="Search by Name"
                                                            type="text"
                                                            value={this.state.input_field_associated_users}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    input_field_associated_users: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="d-flex justify-content-end pe-0">
                                                        <span className="FilterBtn px-0 me-2">
                                                            <Button
                                                                onClick={() => this.resetFilterOtherTrainining('associated_users')}
                                                                outline
                                                                color="secondary"
                                                                className="float-end reset-btn"
                                                                type="button"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </span>
                                                        <span className="FilterBtn"><button type="submit" className="float-end me-2">Filter</button></span>
                                                    </div>
                                                </div>
                                            </AvForm>
                                            <div className="row mx-0">
                                                <div className="d-flex justify-content-between px-0 align-items-center TableTopArea">
                                                    <span><label className="mb-0 d-flex">
                                                        <input
                                                            className="me-1"
                                                            type="checkbox"
                                                            checked={this.state.selectAllAssociatedUserIds}
                                                            onChange={(e) => this.handleSelectAll(e, 'associated_users')}
                                                        />
                                                        Select All</label></span>

                                                </div>
                                                <table className='table table-bordered table-striped TagsTable mt-2'>
                                                    <thead>
                                                        <tr>
                                                            <th>Select</th>
                                                            <th>Name</th>
                                                            <th>Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.associated_users.map((row, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state.selectedUserIdsAssociated.includes(row.id)}
                                                                            onChange={() => this.handleCheckboxChange(row.id, 'associated_users')}
                                                                        />
                                                                    </td>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.type}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="LeftRightArrow">
                                                {this.state.associated_users.length === 0 ? (
                                                    <Link className="disable" to='#'><i disabled class="typcn typcn-media-fast-forward"></i></Link>
                                                ) : (
                                                    <Link to='#' onClick={() => this.handleLinkClick('associated_users')}><i class="typcn typcn-media-fast-forward"></i></Link>
                                                )}
                                                {this.state.users.length === 0 ? (
                                                    <Link className="disable" to='#'><i disabled class="typcn typcn-media-rewind"></i></Link>
                                                ) : (
                                                    <Link to='#' onClick={() => this.handleLinkClick('users')}><i class="typcn typcn-media-rewind"></i></Link>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 ps-4">
                                            <AvForm
                                                onValidSubmit={this.handleFilter}
                                                ref={(c) => (this.form_user = c)}
                                                className="needs-validation"
                                            >
                                                <AvField
                                                    type="hidden"
                                                    name="type"
                                                    value={"users"}
                                                />
                                                <label>Other Users</label>
                                                <div className="row mx-0 align-items-center">
                                                    <label className="col-lg-2 m-0" style={{ paddingLeft: 0, paddingRight: 0 }}>Filter By</label>
                                                    <div className="col-lg-5 ps-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="user_type"
                                                            value={this.state.current_user_id}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                this.setState({
                                                                    current_user_id: value
                                                                });
                                                            }}
                                                        >
                                                            <option value={""}>All</option>
                                                            <option value={"CoachPartner"}>CoachPartner</option>
                                                            <option value={"Client"}>Client</option>
                                                        </AvField>
                                                    </div>
                                                    <div className="col-lg-5 px-0">
                                                        <AvField
                                                            name="user_input"
                                                            label=""
                                                            className="form-control"
                                                            placeholder="Search by Name"
                                                            type="text"
                                                            value={this.state.input_field_users}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    input_field_users: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="d-flex justify-content-end pe-0">
                                                        <span className="FilterBtn px-0 me-2">
                                                            <Button
                                                                onClick={() => this.resetFilterOtherTrainining('users')}
                                                                outline
                                                                color="secondary"
                                                                className="float-end reset-btn"
                                                                type="button"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </span>
                                                        <span className="FilterBtn"><button type="submit" className="float-end me-2">Filter</button></span>
                                                    </div>
                                                </div>
                                            </AvForm>
                                            <div className="row mx-0">
                                                <div className="d-flex justify-content-between px-0 align-items-center TableTopArea">
                                                    <span><label className="mb-0 d-flex">
                                                        <input
                                                            className="me-1"
                                                            type="checkbox"
                                                            checked={this.state.selectAllUsers}
                                                            onChange={(e) => this.handleSelectAll(e, 'users')}
                                                        />
                                                        Select All</label></span>

                                                </div>
                                                <table className='table table-bordered table-striped TagsTable mt-2'>
                                                    <thead>
                                                        <tr>
                                                            <th>Select</th>
                                                            <th>Name</th>
                                                            <th>Role</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users.map((row, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state.selectedUserIds.includes(row.id)}
                                                                            onChange={() => this.handleCheckboxChange(row.id, 'users')}
                                                                        />
                                                                    </td>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.type}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                {/* <Tab eventKey="trainings" title="Trainings" className="pt-3">
                                    {this.state.showEditButton && (
                                        <div className="row">
                                            <div className="col-lg-6 ps-3">
                                                <AvForm onValidSubmit={this.handleFilterAssociated} ref={(c) => (this.form_training = c)} className="needs-validation">
                                                    <AvField
                                                        type="hidden"
                                                        name="type"
                                                        value={"trainings"}
                                                    />
                                                    <label>Associated Trainings</label>
                                                    <div className="row mx-0 align-items-center">
                                                        <label className="col-lg-2 m-0" style={{ paddingLeft: 0, paddingRight: 0 }}>Filter By</label>
                                                        <div className="col-lg-10 m-0">
                                                            <div className="row">
                                                                <div className="col-lg-6 mb-2">
                                                                    <AvField
                                                                        className="select form-control"
                                                                        type="select"
                                                                        name="cat_id"
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            this.handleActionChange('master_id', value, 'associated_subjects');
                                                                        }}
                                                                        value={this.state.current_assoc_cat_id}
                                                                    >
                                                                        <option value={""}>All Courses</option>
                                                                        {this.state.categories && this.state.categories.map((row, i) => {
                                                                            return (
                                                                                <option value={row.cat_id}>{row.title}</option>
                                                                            )
                                                                        })
                                                                        }
                                                                    </AvField>
                                                                </div>
                                                                {this.state.show_associated_subject &&
                                                                    <div className="col-lg-6 mb-2">
                                                                        <AvField
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="subject_id"
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                this.handleActionChange('master_id', value, 'associated_documents');
                                                                            }}
                                                                            value={this.state.current_assoc_subject_id}
                                                                        >
                                                                            <option value={""}>All Modules</option>
                                                                            {this.state.subjects && this.state.subjects.map((row, i) => {
                                                                                return (
                                                                                    <option value={row.cat_id}>{row.title}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </AvField>
                                                                    </div>
                                                                }
                                                                {this.state.show_associated_document &&
                                                                    <div className="col-lg-6">
                                                                        <AvField
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="document_id"
                                                                            onChange={(e) => {
                                                                                this.setState({ current_document_id: e.target.value });
                                                                            }}
                                                                            value={this.state.current_assoc_document_id}
                                                                        >
                                                                            <option value={""}>All Lessons</option>
                                                                            {this.state.documents && this.state.documents.map((row, i) => {
                                                                                return (
                                                                                    <option value={row.cat_id}>{row.title}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </AvField>
                                                                    </div>
                                                                }
                                                                <div className="col-lg-6">
                                                                    <AvField
                                                                        name="training_input"
                                                                        className="form-control"
                                                                        placeholder="Search by Name"
                                                                        type="text"
                                                                        value={this.state.input_field_associated_trainings}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                input_field_associated_trainings: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 ">
                                                        <div className="d-flex justify-content-end pe-0">
                                                            <span className="FilterBtn px-0 me-2">
                                                                <Button
                                                                    onClick={() => this.resetFilterOtherTrainining('associated_trainings')}
                                                                    outline
                                                                    color="secondary"
                                                                    className="float-end reset-btn"
                                                                    type="button"
                                                                >
                                                                    Reset
                                                                </Button>
                                                            </span>
                                                            <span className="FilterBtn px-0"><button type="submit" className="float-end">Filter</button></span>
                                                        </div>
                                                    </div>
                                                </AvForm>
                                                <div className="row mx-0">
                                                    <div className="d-flex justify-content-between px-0 align-items-center TableTopArea">
                                                        <span>
                                                            <label className="mb-0 d-flex">
                                                                <input
                                                                    className="me-1"
                                                                    type="checkbox"
                                                                    checked={this.state.selectAllAssociated}
                                                                    onChange={(e) => this.handleSelectAll(e, 'associated_trainings')}
                                                                />  Select All
                                                            </label>
                                                        </span>

                                                    </div>
                                                    <table className='table table-bordered table-striped TagsTable mt-2 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Topics</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.associated_trainings.map((row, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={this.state.selectedIdsAssociated.includes(row.id)}
                                                                                onChange={() => this.handleCheckboxChange(row.id, 'associated_trainings')}
                                                                            />
                                                                        </td>
                                                                        <td>{row.title}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="LeftRightArrow">
                                                    {this.state.associated_trainings.length === 0 ? (
                                                        <Link className="disable" to='#'><i disabled class="typcn typcn-media-fast-forward"></i></Link>
                                                    ) : (
                                                        <Link to='#' onClick={() => this.handleLinkClick('associated_trainings')}><i class="typcn typcn-media-fast-forward"></i></Link>
                                                    )}
                                                    {this.state.trainings.length === 0 ? (
                                                        <Link className="disable" to='#'><i disabled class="typcn typcn-media-rewind"></i></Link>
                                                    ) : (
                                                        <Link to='#' onClick={() => this.handleLinkClick('trainings')}><i class="typcn typcn-media-rewind"></i></Link>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 ps-4">
                                                <AvForm
                                                    onValidSubmit={this.handleFilter}
                                                    ref={(c) => (this.form_training = c)}
                                                    className="needs-validation"
                                                >
                                                    <AvField
                                                        type="hidden"
                                                        name="type"
                                                        value={"trainings"}
                                                    />
                                                    <label>Other Trainings</label>
                                                    <div className="row mx-0 align-items-center">
                                                        <label className="col-lg-2 m-0" style={{ paddingLeft: 0, paddingRight: 0 }}>Filter By</label>
                                                        <div className="col-lg-10">
                                                            <div className="row">
                                                                <div className="col-lg-6 mb-2">
                                                                    <AvField
                                                                        className="select form-control"
                                                                        type="select"
                                                                        name="cat_id"
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            this.handleActionChange('master_id', value, 'subjects');
                                                                        }}
                                                                        value={this.state.current_cat_id}
                                                                    >
                                                                        <option value={""}>All Courses</option>
                                                                        {this.state.categories && this.state.categories.map((row, i) => {
                                                                            return (
                                                                                <option value={row.cat_id}>{row.title}</option>
                                                                            )
                                                                        })
                                                                        }
                                                                    </AvField>
                                                                </div>
                                                                {this.state.show_subject &&
                                                                    <div className="col-lg-6 mb-2">
                                                                        <AvField
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="subject_id"
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                this.handleActionChange('master_id', value, 'documents');
                                                                            }}
                                                                            value={this.state.current_subject_id}
                                                                        >
                                                                            <option value={""}>All Modules</option>
                                                                            {this.state.subjects && this.state.subjects.map((row, i) => {
                                                                                return (
                                                                                    <option value={row.cat_id}>{row.title}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </AvField>
                                                                    </div>
                                                                }
                                                                {this.state.show_document &&
                                                                    <div className="col-lg-6">
                                                                        <AvField
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="document_id"
                                                                            onChange={(e) => {
                                                                                this.setState({ current_document_id: e.target.value });
                                                                            }}
                                                                            value={this.state.current_document_id}
                                                                        >
                                                                            <option value={""}>All Lessons</option>
                                                                            {this.state.documents && this.state.documents.map((row, i) => {
                                                                                return (
                                                                                    <option value={row.cat_id}>{row.title}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </AvField>
                                                                    </div>
                                                                }
                                                                <div className="col-lg-6">
                                                                    <AvField
                                                                        name="training_input"
                                                                        label=""
                                                                        className="form-control"
                                                                        placeholder="Search by Name"
                                                                        type="text"
                                                                        value={this.state.input_field_trainings}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                input_field_trainings: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0">
                                                        <div className="d-flex justify-content-end pe-0">
                                                            <span className="FilterBtn px-0 me-2">
                                                                <Button
                                                                    onClick={() => this.resetFilterOtherTrainining('trainings')}
                                                                    outline
                                                                    color="secondary"
                                                                    className="float-end reset-btn"
                                                                    type="button"
                                                                >
                                                                    Reset
                                                                </Button>
                                                            </span>
                                                            <span className="FilterBtn px-0"><button type="submit" className="float-end">Filter</button></span>
                                                        </div>
                                                    </div>
                                                </AvForm>
                                                <div className="row mx-0">
                                                    <div className="d-flex justify-content-between px-0 align-items-center TableTopArea">
                                                        <span>
                                                            <label className="mb-0 d-flex">
                                                                <input
                                                                    className="me-1"
                                                                    type="checkbox"
                                                                    checked={this.state.selectAll}
                                                                    onChange={(e) => this.handleSelectAll(e, 'trainings')}
                                                                />
                                                                Select All
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <table className='table table-bordered table-striped TagsTable mt-2 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Topics</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.trainings.map((row) => (
                                                                <tr key={row.id}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state.selectedIds.includes(row.id)}
                                                                            onChange={() => this.handleCheckboxChange(row.id, 'trainings')}
                                                                        />
                                                                    </td>
                                                                    <td>{row.title}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Tab> */}

                            </Tabs>
                        </ModalBody>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.modal_3}
                    role="dialog"
                    centered={true}
                    className="exampleModal TagsPopup"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            modal_3: !this.state.modal_3,
                            record: {}
                        });
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    modal_3: !this.state.modal_3,
                                    record: {}
                                });
                            }}
                            className="text-transform-capitalize"
                        >
                            {'Manage Training Groups'}
                        </ModalHeader>


                        <ModalBody>

                            <AdminTagGroup />

                        </ModalBody>

                        <ModalFooter className="modal-footer d-inline-block">




                        </ModalFooter>


                    </div>
                </Modal>

            </ErrorBoundary >
        );
    }
}
