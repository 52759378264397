import React, { Component, Fragment } from "react";
import { Row, Col, Input, Label, Card } from "reactstrap";
import EmailSideBar from "./email-sidebar";
import EmailToolbar from "./email-toolbar";
import { toast, Flip } from "react-toastify";
import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uToken } from "../../useToken";
import { Link } from "react-router-dom";

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

const removeUnwantedText = (text) => {
  const unwantedText = "Click here to unsubscribe";
  return text.replace(unwantedText, "").trim();
};

const noRecordsStyle = {
  textAlign: 'center',
  padding: '20px',
  fontSize: '18px',
  color: '#888',
  borderTop: '1px solid #ddd',
  backgroundColor: '#f9f9f9',
};

const iconStyle = {
  fontSize: '24px',
  color: '#888',
  display: 'block',
  marginBottom: '10px',
};

export default class SentEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      totalEmails: 0,
      currentPage: 1,
      pageSize: 10,
      total_pages: 0,
      selectedEmails: [],
      selectAll: false,
      searchTerm: "",
      noRecordsFound: false,
    };
  }

  componentDidMount() {
    this.fetchEmails();
  }

  fetchEmails = (search = false) => {
    const { currentPage, pageSize, searchTerm } = this.state;
    const params = new URLSearchParams();
    const report_id = params.get('show') ? dcrypt(params.get('show')) : '';

    const queryParams = new URLSearchParams({
      report_type: 'email',
      report_id,
      page_number: currentPage,
      page_size: pageSize,
      direction: 'out',
      status: 'sent',
      search: search ? searchTerm : undefined,
    }).toString();

    fetch(`${ApiUrl}aio/report/email${search ? '/search' : ''}?${queryParams}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            emails: response.data.records,
            totalEmails: response.data.total,
            total_pages: Math.ceil(response.data.total / pageSize),
            noRecordsFound: response.data.records.length === 0,
          });
        } else {
          this.showError(response.message);
        }
      })
      .catch(() => this.showError("Error fetching emails"));
  };

  showError = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      position: "top-center",
      transition: Flip,
    });
  };

  handleSelectEmail = (emailId) => {
    this.setState((prevState) => {
      const selectedEmails = prevState.selectedEmails.includes(emailId)
        ? prevState.selectedEmails.filter(id => id !== emailId)
        : [...prevState.selectedEmails, emailId];
      return { selectedEmails, selectAll: false };
    });
  };

  handleSelectAll = () => {
    this.setState((prevState) => {
      const selectAll = !prevState.selectAll;
      const selectedEmails = selectAll ? this.state.emails.map(email => email.report_id) : [];
      return { selectedEmails, selectAll };
    });
  };

  refreshEmails = () => {
    this.fetchEmails();
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, this.fetchEmails);
  };

  handlePageSizeChange = (size) => {
    this.setState({ pageSize: size, currentPage: 1 }, this.fetchEmails);
  };

  handleSearchChange = (searchTerm) => {
    this.setState({ searchTerm, currentPage: 1 }, () => this.fetchEmails(true));
  };

  render() {
    const { emails, currentPage, totalEmails, pageSize, selectedEmails, selectAll, noRecordsFound } = this.state;

    return (
      <Fragment>
        <Row>
          <Col xs="12">
            <EmailSideBar />
            <div className="email-rightbar mb-3">
              <Card>
                <EmailToolbar
                  currentPage={currentPage}
                  totalEmails={totalEmails}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                  hidePagination={false}
                  selectedEmails={selectedEmails}
                  refreshEmails={this.refreshEmails}
                  toggleSelectAll={this.handleSelectAll}
                  selectAll={selectAll}
                  onSearchChange={this.handleSearchChange}
                />
                {noRecordsFound ? (
                  <div style={noRecordsStyle}>
                    <i className="fas fa-folder-open" style={iconStyle}></i>
                    <p>No records found</p>
                  </div>
                ) : (
                  <ul className="message-list">
                    {emails.length ? emails.map(email => (
                      <li key={email.report_id}>
                        <div className="col-mail col-mail-1">
                          <div className="checkbox-wrapper-mail">
                            <Input
                              type="checkbox"
                              name="report_id"
                              id={`${email.report_id}`}
                              checked={selectedEmails.includes(email.report_id) || selectAll}
                              onChange={() => this.handleSelectEmail(email.report_id)}
                            />
                            <Label htmlFor={`${email.report_id}`} className="toggle" />
                          </div>
                          <Link
                            to={`/email/sent/read/${bcrypt(email.report_id)}`}
                            className="title"
                          >
                            {email.name || 'Unknown Recipient'}
                          </Link>
                        </div>
                        <div className="col-mail col-mail-2">
                          <Link
                            to={`/email/sent/read/${bcrypt(email.report_id)}`}
                            className="subject"
                          >
                            {email.subject || "No subject"} – 
                            <span className="teaser">
                              {email.body ? removeUnwantedText(stripHtmlTags(email.body)).substring(0, 100) : "No preview available"}
                            </span>
                          </Link>
                          <div style={{ paddingLeft: '0px' }} className="date">
                            {email.created_at || "Date not available"}
                          </div>
                        </div>
                      </li>
                    )) : (
                      <li>No sent emails available</li>
                    )}
                  </ul>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
