import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, Media, Button, Form, FormGroup, Input } from "reactstrap";
import avatar1 from "../../assets/images/users/user-4.jpg";
import { ApiUrl } from "../../config";
import { dcrypt, uToken } from "../../useToken";
import { toast, Flip } from "react-toastify";
import EmailSideBar from "./email-sidebar";
import EmailToolbar from "./email-toolbar";

class SentEmailRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      loading: true,
      error: null,
      replyContent: '',
      isReplying: false,
    };
  }

  componentDidMount() {
    this.getEmailRead();
  }

  getEmailRead = () => {
    const { report_id } = this.props.match.params;
    const decryptedReportId = dcrypt(report_id);

    fetch(`${ApiUrl}aio/report/read/email?report_id=${decryptedReportId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === true) {
          this.setState({
            email: data.data,
            loading: false
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error'
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Error fetching email", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error'
        });
      });
  };

  handleReplyChange = (e) => {
    this.setState({ replyContent: e.target.value });
  };

  toggleReplyBox = () => {
    this.setState({ isReplying: !this.state.isReplying });
  };

  sendReplyEmail = () => {
    const { email, replyContent } = this.state;

    fetch(`${ApiUrl}aio/reply/email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        report_id: email.report_id,
        reply_content: replyContent,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          toast("Reply sent successfully!", {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls success',
          });
          this.setState({ replyContent: '', isReplying: false });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error',
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Error sending reply", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error',
        });
      });
  };

  render() {
    const { email, loading, error, replyContent, isReplying } = this.state;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!email) return <div>No email found</div>;

    return (
      <React.Fragment>
        <MetaTags>
          <title>Sent Email Read</title>
        </MetaTags>
        <Row>
          <Col xs="12">
            <EmailSideBar />

            <div className="email-rightbar mb-3">
              <Card>
                <EmailToolbar hidePagination={true} hideSearch={true} />

                <CardBody>
                  <Media className="mb-4">
                    <img
                      className="d-flex me-3 rounded-circle avatar-sm"
                      src={avatar1}
                      alt="avatar"
                    />
                    <div style={{ flex: 1, }}>
                      <h4 style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          margin: 0,
                          color: '#202124',
                        }}>
                        {email.name || 'Unknown Sender'}
                      </h4>
                      <p
                        style={{
                          fontSize: '13px',
                          color: '#5f6368', 
                          margin: 0,
                        }}
                      >
                        From: {email.froms || 'No email address'}
                      </p>
                      <p
                        style={{
                          fontSize: '13px',
                          color: '#5f6368', 
                          margin: 0,
                        }}
                      >
                        To: {email.tos || 'No email address'}
                      </p>
                    </div>
                  </Media>

                  <h4 className="mt-0 font-size-16">
                    {email.subject || "No Subject"}
                  </h4>

                  <div dangerouslySetInnerHTML={{ __html: email.body }}></div>

                  {!isReplying && (
                    <Button
                      color="light"
                      className="btn-reply waves-effect mt-5"
                      onClick={this.toggleReplyBox}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        border: '1px solid #ddd',
                        borderRadius: '20px',
                        padding: '6px 16px',
                        backgroundColor: '#f6f6f6',
                        color: '#333',
                      }}
                    >
                      <i className="mdi mdi-reply"></i> Reply
                    </Button>
                  )}

                  {isReplying && (
                    <div className="mt-5">
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <strong>Reply to: </strong>
                        <span style={{ marginLeft: '8px', color: '#555' }}>{email.name} ({email.froms})</span>
                      </div>

                      <Form>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="replyContent"
                            id="replyContent"
                            rows="4"
                            value={replyContent}
                            onChange={this.handleReplyChange}
                            placeholder="Write your reply here..."
                            style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '10px' }}
                          />
                        </FormGroup>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div>
                            <Button
                              color="primary"
                              onClick={this.sendReplyEmail}
                              style={{
                                backgroundColor: '#1a73e8',
                                border: 'none',
                                borderRadius: '20px',
                                padding: '6px 16px'
                              }}
                            >
                              Send
                            </Button>{' '}
                            <Button
                              color="secondary"
                              onClick={this.toggleReplyBox}
                              style={{
                                border: '1px solid #ddd',
                                borderRadius: '20px',
                                padding: '6px 16px',
                                backgroundColor: '#f6f6f6',
                                color: '#333'
                              }}
                            >
                              Discard
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SentEmailRead;
