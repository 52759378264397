import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Card } from "reactstrap";

const EmailSideBar = ({ unreadCount }) => {
  const location = useLocation();  

  return (
    <Card className="email-leftbar">
      <Button
        type="button"
        color="danger"
        className="rounded btn-custom btn-block waves-effect waves-light"
      >
        <Link to="/email/compose" className="text-white">
          Compose
        </Link>
      </Button>
      <div className="mail-list mt-3">
        <Link
          to="/email/inbox"
          className={location.pathname === "/email/inbox" ? "active" : ""}
        >
          Inbox
          {unreadCount > 0 && (
            <span className="ms-1">({unreadCount})</span>
          )}
        </Link>
        <Link
          to="/email/sent"
          className={location.pathname === "/email/sent" ? "active" : ""}
        >
          Sent Mail
        </Link>
      </div>
    </Card>
  );
};

export default EmailSideBar;
