import React, { Component } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import swal from "sweetalert";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";

class EmailToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_Menu: false,
      tag_Menu: false,
      more_Menu: false,
      isSearch: false,
      existing: [],
      isClient: true,
      searchTerm: "",
    };
  }

  toggleFolderMenu = () => {
    this.setState((prevState) => ({ folder_Menu: !prevState.folder_Menu }));
  };

  toggleTagMenu = () => {
    this.setState((prevState) => ({ tag_Menu: !prevState.tag_Menu }));
  };

  toggleMoreMenu = () => {
    this.setState((prevState) => ({ more_Menu: !prevState.more_Menu }));
  };

  toggleSearch = () => {
    this.setState((prevState) => ({ isSearch: !prevState.isSearch }));
  };

  handleDelete = () => {
    const { selectedEmails, refreshEmails } = this.props;

    if (selectedEmails.length === 0) {
      swal({
        title: "No emails selected",
        text: "Please select at least one email to delete.",
        icon: "warning",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "You want to delete the selected emails.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        console.log('Deleting emails with IDs:', selectedEmails);

        fetch(`${ApiUrl}aio/delete/email`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify({ report_id: selectedEmails }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              swal("Success", "Selected emails deleted successfully!", "success");
              refreshEmails();
            } else {
              swal("Error", data.message, "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            swal("Error", "Error deleting emails", "error");
          });
      }
    });
  };

  searchClient = (searchTerm) => {
    this.setState({ searchTerm });

    // Call the search API to fetch search results
    fetch(`${ApiUrl}aio/report/email/search?search=${searchTerm}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.setState({ existing: data.data.records, isClient: true });
        } else {
          this.setState({ existing: [], isClient: false });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ existing: [], isClient: false });
      });

    this.props.onSearchChange(searchTerm);
  };

  render() {
    const {
      currentPage,
      totalEmails,
      pageSize,
      onPageChange,
      onPageSizeChange,
      hidePagination,
      selectedEmails,
      hideSearch
    } = this.props;
    const { tag_Menu, more_Menu, isSearch, existing, isClient, searchTerm } = this.state;

    const totalPages = Math.ceil(totalEmails / pageSize);

    return (
      <React.Fragment>
        <div className="btn-toolbar p-3 d-flex align-items-center" role="toolbar">
          <Button
            type="button"
            color="danger"
            className="waves-light waves-effect"
            onClick={this.handleDelete}
          >
            <i className="far fa-trash-alt" />
          </Button>
          <div style={{ marginLeft: "8px" }} className="d-flex flex-grow-1 align-items-center">
            {!hideSearch && (
              <div className="search-container position-relative flex-grow-1">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search emails..."
                  value={searchTerm}
                  onChange={(e) => this.searchClient(e.target.value)}
                  onFocus={() => this.setState({ isSearch: true })}
                  onBlur={() => setTimeout(() => this.setState({ isSearch: false }), 200)}
                />
              </div>
            )}
          </div>

          {/* Pagination Controls */}
          {!hidePagination && (
            <div className="pagination-controls d-flex align-items-center ms-3">
              <span
                className="text-muted"
                style={{
                  padding: "6px 12px",
                  borderRadius: "20px",
                  backgroundColor: "#f1f3f4",
                  color: "#5f6368",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  margin: "0 8px",
                }}
              >
                {`${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, totalEmails)} of ${totalEmails}`}
              </span>

              <Button
                color="primary"
                className="waves-light waves-effect me-2"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className="mdi mdi-chevron-left" />
              </Button>

              <Button
                color="primary"
                className="waves-light waves-effect"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <i className="mdi mdi-chevron-right" />
              </Button>

              <Dropdown isOpen={tag_Menu} toggle={this.toggleTagMenu} className="ms-2">
                <DropdownToggle
                  className="btn btn-primary waves-light waves-effect dropdown-toggle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "4px 8px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <span>{pageSize}</span>
                  <i className="mdi mdi-chevron-down" style={{ marginLeft: "8px", fontSize: "16px" }} />
                </DropdownToggle>

                <DropdownMenu
                  className="dropdown-menu-end"
                  style={{
                    borderRadius: "8px",
                    minWidth: "100px",
                  }}
                >
                  {[10, 20, 50, 100].map((size) => (
                    <DropdownItem
                      key={size}
                      onClick={() => onPageSizeChange(size)}
                      style={{
                        padding: "8px 16px",
                        borderRadius: "4px",
                      }}
                      className="d-flex align-items-center"
                    >
                      {size}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EmailToolbar;
