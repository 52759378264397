import React, { Component, Fragment } from "react"
import { 
  Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, 
  Input, ModalBody, ModalFooter,Nav,NavItem,NavLink,
  TabContent,
  TabPane, 
} from "reactstrap"

import Helmet from "react-helmet"
import axios from "axios"
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation"
import { uToken, dcrypt, uInfo } from "../../useToken";
import { getCoachPages, getStaffPages, getCoachPartnerPages } from "../../components/Common/PagesLibrary";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class PermissionCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id? dcrypt(this.props.match.params.id): null,
      record: {
        pages: [],
        type: uInfo().type == 'Coach'  ? 'partner' : 'coach',
        status: 'Active',
      },
      groups_tags_records: [],
      phone_records: [],
      pipeline_records: [],
      assesment_records: [],
      campaigns_records: [],
      permission: '',
      page_name: "User Permission",
      coach_pages_array: uInfo().type == 'Coach' ? getCoachPartnerPages() : getCoachPages(),
      isCheckAll: {},
      isCheck: {},
      activeTab: '1',
      isGroupCheck: false,
      isPhoneNumberCheck: false,
      isPipelineCheck: false,
      isAssessmentCheck: false,
      isCampaignCheck: false,
      isTraningTagsCheck:false,
    }
  }

  componentDidMount() {
    this.state.id != null && this.getPagesData();
  }
  getTrainningTags = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/trainingtags?login_user_type=coach&page_size=500", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({user_id:this.state.id}),
       
    })
        .then((response) => response.json())
        
        .then((response) => {
            if (response.status === true) {
                // this.setState({
                //     total_pages: response.data.total,
                //     records: response.data.records,
                //     roles: response.data.roles,

                // });
                this.setState({
                  traningtags_records: response.data.records,
              });
                
            } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};
  getCampaigns= () => {
    fetch(`${ApiUrl}` + "campaigns", {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
      },
    }).then((response) => response.json())
      .then((response) => {
          if (response.status === true) {
              this.setState({
                  campaign_records: response.data.records,
              });
          } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
      })
      .catch((error) => {
          console.error("Error:", error);
      });
  };
  getAssessments = () => {
    fetch(`${ApiUrl}` + "assessments", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
    })
        .then((response) => response.json())
        .then((response) => {

            if (response.status === true) {
                this.setState({
                    assessment_records: response.data.records,
                });
            } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
  };
  getPipelie = () => {
    fetch(`${ApiUrl}` + "get/master/pipeline", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({selected_pipeline: 0})
    })
        .then((response) => response.json())
        .then((response) => {

            if (response.status === true) {
                this.setState({
                    pipeline_records: response.data.records,
                });
            } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
}
  geActivePhones = () => {
    fetch(`${ApiUrl}` + "get/user/phone/numbers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({'phone_status': "Active" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            phone_records: response.data.records,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getPagesData = (queryString = "") => {
    fetch(`${ApiUrl}` + 'getEdit/user/permission/'+this.state.id, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.setState({
            coach_pages_array: data.data.record.type == 'coach' ? getCoachPages() : data.data.record.type == 'partner' ? getCoachPartnerPages() : getStaffPages()
          })

          this.setState({
            record: data.data.record
          })
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  getGroupsTagsData = (queryString = "", data) => {
    let url = `${ApiUrl}` + "groupstags";
   
    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups_tags_records: response.data.records,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleMulti = (selectedMulti) => {
    var filter = [];
    if (selectedMulti) {
      selectedMulti.map(fil => {
        filter.push(fil.value);
      })
    }

    this.setState({
      selectedMulti: selectedMulti,
      pages: filter.toString()
    })
  }

  handlePermissionMulti = (selectedPermissionMulti) => {
    var filter = [];
    if (selectedPermissionMulti) {
      selectedPermissionMulti.map(fil => {
        filter.push(fil.value);
      })
    }

    this.setState({
      selectedPermissionMulti: selectedPermissionMulti,
      permission: filter.toString()
    })
  }
  handleSubmit = async (event, values) => {
    if (this.state.record) {
      values.user_id = this.state.id
    }
    
    fetch(`${ApiUrl}` + 'perform/user/permission', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) { 
          swal({
            text: "Action performed successfully",
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  handleGroupSubmit = async (event, values) => {
    if (this.state.record) {
      values.id = this.state.record.id
    }
    fetch(`${ApiUrl}` + 'edit/permission/library', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) { 
          swal({
            text: "Action performed successfully",
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  handleSelectAll = (e, sub_tabs, key) => {
    this.setState(prevState => ({
      isCheckAll: {                   // object that we want to update
        ...prevState.isCheckAll,    // keep all other key-value pairs
        [key]: !this.state.isCheckAll[key]    // update the value of specific key
      }
    }))
   

    if (!e) {
      this.setState(prevState => ({
        isCheck: {                   // object that we want to update
          ...prevState.isCheck,    // keep all other key-value pairs
          [key]: []    // update the value of specific key
        }
      }))


      let a  = this.state.record.pages;
      let b  =  sub_tabs.map(li => li.key) 

      let c  = a.filter(item => !b.includes(item));
      let d = c.filter(function(ele){ 
        return ele != key; 
    });
      
      this.setState(prevState => ({
        record: {                   // object that we want to update
          ...prevState.record,    // keep all other key-value pairs
          pages: d    // update the value of specific key
        }
      }))

    }
    if (e) {
      this.setState(prevState => ({
        isCheck: {                   // object that we want to update
          ...prevState.isCheck,    // keep all other key-value pairs
          [key]: sub_tabs.map(li => li.key)    // update the value of specific key
        }
      }))
    }

  }
  handleSelectAllAct = (e, key) => {
    if(key == 'group'){
      if (!e) {
        this.setState({isGroupCheck: false})
      }
      if (e) {
        this.setState({isGroupCheck: true})
      }
    }
    if(key == 'phone_number'){
      if (!e) {
        this.setState({isPhoneNumberCheck: false})
      }
      if (e) {
        this.setState({isPhoneNumberCheck: true})
      }
    }
    if(key == 'pipeline'){
      if (!e) {
        this.setState({isPipelineCheck: false})
      }
      if (e) {
        this.setState({isPipelineCheck: true})
      }
    }
    if(key == 'assessment'){
      if (!e) {
        this.setState({isAssessmentCheck: false})
      }
      if (e) {
        this.setState({isAssessmentCheck: true})
      }
    }
    if(key == 'campaign'){
      if (!e) {
        this.setState({isCampaignCheck: false})
      }
      if (e) {
        this.setState({isCampaignCheck: true})
      }
    }
    if(key == 'traningtag'){
      if (!e) {
        this.setState({isTraningTagsCheck: false})
      }
      if (e) {
        this.setState({isTraningTagsCheck: true})
      }
    }
  }
  handleSubClick = (e, key) => {
    if (!this.state.isCheckAll[key]) {
      this.setState(prevState => ({
        isCheckAll: {                   // object that we want to update
          ...prevState.isCheckAll,    // keep all other key-value pairs
          [key]: !this.state.isCheckAll[key]    // update the value of specific key
        }
      }))
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
        }
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.id == null ? "Add Permission Library" : "Edit Team Permission"}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_nam}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.push(`/settings/coachpartners`)}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Card className="mb-0">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 1 ? "active" : ""}
                onClick={() => {
                  this.toggle("1");

                }}
              >
                Pages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 2 ? "active" : ""}
                onClick={() => {
                  this.toggle("2");
                  this.getGroupsTagsData();
                }}
              >
                Group & Tags
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 3 ? "active" : ""}
                onClick={() => {
                  this.toggle("3");
                  this.geActivePhones();
                }}
              >
                Phone Numbers
              </NavLink>
            </NavItem>
              <NavItem>
              <NavLink
                className={this.state.activeTab == 4 ? "active" : ""}
                onClick={() => {
                  this.toggle("4");
                  this.getPipelie();
                }}
              >
                Pipelines
              </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 5 ? "active" : ""}
                  onClick={() => {
                    this.toggle("5");
                    this.getAssessments();
                  }}
                >
                  Assesments
                </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab == 6 ? "active" : ""}
                    onClick={() => {
                      this.toggle("6");
                      this.getCampaigns();
                    }}
                  >
                    Campaigns
                  </NavLink>
              </NavItem>

              <NavItem>
                  <NavLink
                    className={this.state.activeTab == 7 ? "active" : ""}
                    onClick={() => {
                      this.toggle("7");
                      this.getTrainningTags("coach_training_tag");
                    }}
                  >
                    Training Tags
                  </NavLink>
              </NavItem>

          </Nav>
        </Card>
      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                  <AvField type="hidden" name="permission_type" value="pages"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Name"
                        value={this.state.record.name}
                        className="form-control"
                        placeholder="Enter Name"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="type"
                        label="Role for"
                        value={this.state.record.type}
                        className="form-control"
                        type="select"
                        onChange={(e) => this.setState({
                          coach_pages_array: e.target.value == 'coach' ? getCoachPages() : e.target.value == 'partner' ? getCoachPartnerPages() : getStaffPages()
                        })}
                      >
                        {
                          uInfo().type == 'Coach' ? (
                            <option value={'partner'}>Team</option>

                          ) : (
                            <>
                              <option value={'coach'}>Coach</option>
                              <option value={'staff'}>Team Admin</option>
                            </>
                          )
                        }

                      </AvField>

                    </div>


                    <div className="mb-3">
                      <label className="control-label">
                        Pages Permission
                      </label>
                      <div className="row">
                        {this.state.coach_pages_array && (
                          this.state.coach_pages_array.map((row, key) => {
                            return (
                              <div className="col-md-12 mb-3" key={key}>
                                <AvGroup check className="mb-2">
                                  <AvInput
                                    checked={this.state.record.pages.indexOf(row.key) > -1 || this.state.isCheckAll[row.key] ? 'checed' : ''}
                                    type="checkbox" name={`master_pages[${row.key}]`}
                                    onClick={(e) => this.handleSelectAll(e.target.checked, row.sub_tabs, row.key)}
                                  //isChecked={this.state.isCheckAll[row.key]}
                                  />
                                  <Label check htmlFor="checkbox" className="fw-bolder"> {row.label}</Label>
                                </AvGroup>
                                <div className="row">
                                  {row.sub_tabs.length > 0 && (
                                    row.sub_tabs.map((sub_row, i) => {
                                      return (
                                        <div className="col-md-2 mb-3">

                                          <AvGroup check>
                                            <AvInput
                                              checked={this.state.record.pages.indexOf(sub_row.key) > -1 || (this.state.isCheck[row.key] && this.state.isCheck[row.key].includes(sub_row.key)) ? 'checed' : ''}
                                              type="checkbox" name={`sub_pages[${sub_row.key}]`}
                                              onClick={(e) => this.handleSubClick(e.target.value, row.key)}
                                            // isChecked={this.state.isCheck.includes(sub_row.key)}
                                            />
                                            <Label check htmlFor="checkbox"> {sub_row.label}</Label>
                                          </AvGroup>
                                        </div>
                                      )
                                    })
                                  )}
                                </div>
                              </div>
                            )
                          })
                        )
                        }

                      </div>
                    </div>

                    <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select" id="s" name="status" label="Status" required
                        value={this.state.record.status}>
                        <option value={'Active'}>Active</option>
                        <option value={'Inactive'}>Inactive</option>
                      </AvField>

                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="groups_and_tags"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                    <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`groups[group]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'group')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All</Label>
                        </AvGroup>
                      </div>
                      <label className="control-label mt-2">
                        Groups
                      </label>
                      <div className="row">
                        {this.state.groups_tags_records && (
                            this.state.groups_tags_records.map((row, key) => {
                                  return (
                                    row.type == "Group" && (
                                    <div className="col-md-3 mb-3" key={key}>
                                    {
                                      this.state.isGroupCheck === true ? (
                                          <AvGroup>
                                          <AvInput
                                            checked={true}
                                            type="checkbox" name={`groups[${row.group_id}]`}
                                          />
                                          <Label className="ml-3"  check htmlFor="checkbox"> {row.title}</Label>
                                          </AvGroup>
                                    
                                      ): (
                                        <>
                                        <AvGroup>
                                          <AvInput
                                            defaultChecked={(row.permission.includes(`${this.state.id}`)) ? true : false}
                                            type="checkbox" name={`groups[${row.group_id}]`}
                                          />
                                          <Label className="ml-3"  check htmlFor="checkbox"> {row.title}</Label>
                                          </AvGroup>
                                          </>
                                      )
                                    }
                                    </div>
                                  )
                              )
                            })
                          )
                        }

                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="control-label">
                        Tags
                      </label>
                      <div className="row">
                        {this.state.groups_tags_records && (
                          this.state.groups_tags_records.map((row, key) => {
                              return (
                                row.type == "Tag" && (
                                <div className="col-md-3 mb-3">
                                    {
                                      this.state.isGroupCheck === true ? (
                                          <AvGroup>
                                          <AvInput
                                            checked={true}
                                            type="checkbox" name={`groups[${row.group_id}]`}
                                          />
                                          <Label className="ml-3" check htmlFor="checkbox"> {row.title}</Label>
                                          </AvGroup>
                                    
                                      ): (
                                        <>
                                        <AvGroup>
                                          <AvInput
                                            defaultChecked={(row.permission.includes(`${this.state.id}`)) ? true : false}
                                            type="checkbox" name={`groups[${row.group_id}]`}
                                          />
                                          <Label className="ml-3"  check htmlFor="checkbox"> {row.title}</Label>
                                          </AvGroup>
                                          </>
                                      )
                                    }
                                </div>
                              )
                            )
                          })
                        )
                        }

                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="phone_numbers"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`phone_numbers[phone_number]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'phone_number')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All Phone Numbers</Label>
                        </AvGroup>
                      </div>
                        <div className="row mt-2">
                          {this.state.phone_records && (
                              this.state.phone_records.map((row, key) => {
                                    return (
                                      <div className="col-md-3 mb-3" key={key}>
                                      {
                                        this.state.isPhoneNumberCheck === true ? (
                                            <AvGroup>
                                            <AvInput
                                              checked={true}
                                              type="checkbox" name={`phone_numbers[${row.id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox">  {row.phone_number}</Label>
                                            </AvGroup>
                                      
                                        ): (
                                          <>
                                          <AvGroup>
                                            <AvInput
                                              defaultChecked={(row.permission.includes(`${this.state.id}`)) ? true : false}
                                              type="checkbox" name={`phone_numbers[${row.id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox"> {row.phone_number}</Label>
                                            </AvGroup>
                                            </>
                                        )
                                      }
                                      </div>
                                    
                                )
                              })
                            )
                          }

                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="4">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="pipelines"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`pipelines[pipeline]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'pipeline')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All Pipeline</Label>
                        </AvGroup>
                      </div>
                        <div className="row mt-2">
                          {this.state.pipeline_records && (
                              this.state.pipeline_records.map((row, key) => {
                                    return (
                                      <div className="col-md-3 mb-3" key={key}>
                                      {
                                        this.state.isPipelineCheck === true ? (
                                            <AvGroup>
                                            <AvInput
                                              checked={true}
                                              type="checkbox" name={`pipelines[${row.pipeline_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox">  {row.name}</Label>
                                            </AvGroup>
                                      
                                        ): (
                                          <>
                                          <AvGroup>
                                            <AvInput
                                              defaultChecked={(row.permission_arr.includes(`${this.state.id}`)) ? true : false}
                                              type="checkbox" name={`pipelines[${row.pipeline_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox"> {row.name}</Label>
                                            </AvGroup>
                                            </>
                                        )
                                      }
                                      </div>
                                    
                                )
                              })
                            )
                          }

                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="5">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="assessments"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`assessments[assessment]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'assessment')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All Assessments</Label>
                        </AvGroup>
                      </div>
                        <div className="row mt-2">
                          {this.state.assessment_records && (
                              this.state.assessment_records.map((row, key) => {
                                    return (
                                      <div className="col-md-3 mb-3" key={key}>
                                      {
                                        this.state.isAssessmentCheck === true ? (
                                            <AvGroup>
                                            <AvInput
                                              checked={true}
                                              type="checkbox" name={`assessments[${row.assessment_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox">  {row.name}</Label>
                                            </AvGroup>
                                      
                                        ): (
                                          <>
                                          <AvGroup>
                                            <AvInput
                                              defaultChecked={(row.permission.includes(`${this.state.id}`)) ? true : false}
                                              type="checkbox" name={`assessments[${row.assessment_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox"> {row.name}</Label>
                                            </AvGroup>
                                            </>
                                        )
                                      }
                                      </div>
                                )
                              })
                            )
                          }

                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="6">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="campaigns"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`campaigns[campaign]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'campaign')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All Campaigns</Label>
                        </AvGroup>
                      </div>
                        <div className="row mt-2">
                          {this.state.campaign_records && (
                              this.state.campaign_records.map((row, key) => {
                                    return (
                                      <div className="col-md-3 mb-3" key={key}>
                                      {
                                        this.state.isCampaignCheck === true ? (
                                            <AvGroup>
                                            <AvInput
                                              checked={true}
                                              type="checkbox" name={`campaigns[${row.campaign_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox">  {row.title}</Label>
                                            </AvGroup>
                                      
                                        ): (
                                          <>
                                          <AvGroup>
                                            <AvInput
                                              defaultChecked={(row.permission.includes(`${this.state.id}`)) ? true : false}
                                              type="checkbox" name={`campaigns[${row.campaign_id}]`}
                                            />
                                            <Label className="ml-3" check htmlFor="checkbox"> {row.title}</Label>
                                            </AvGroup>
                                            </>
                                        )
                                      }
                                      </div>
                                    
                                )
                              })
                            )
                          }

                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="7">
          <Row>
            <Col className="col-12">
              <Card className="package-add">
                <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <AvField type="hidden" name="permission_type" value="traningtags"></AvField>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="row">
                          <AvGroup check className="mb-2">
                          <AvInput
                            checked={''}
                            type="checkbox" name={`traningtags[traningtag]`}
                            onClick={(e) => this.handleSelectAllAct(e.target.checked, 'traningtag')}
                          />
                          <Label check htmlFor="checkbox" className="fw-bolder"> All Training Tags</Label>
                        </AvGroup>
                      </div>
                        <div className="row mt-2">
                          {this.state.traningtags_records && (
                              this.state.traningtags_records.map((row, key) => {
                                return (
                                  <div className="col-md-3 mb-3" key={key}>
                                    {this.state.isTraningTagsCheck ? (
                                      <AvGroup>
                                        <AvInput
                                          checked={true}
                                          type="checkbox"
                                          name={`traningtags[${row.id}]`}
                                        />
                                        <Label className="ml-3" check htmlFor={`traningtags[${row.id}]`}>
                                          {row.name}
                                          {row.tag_type === 'my_tag' && (
                                            <>
                                              {" - "}
                                              <span className="badge bg-success">My</span>
                                            </>
                                          )}
                                        </Label>
                                      </AvGroup>
                                    ) : (
                                      <AvGroup>
                                        <AvInput
                                          defaultChecked={row.permission.includes(`${row.id}`)}
                                          type="checkbox"
                                          name={`traningtags[${row.id}]`}
                                        />
                                        <Label className="ml-3" check htmlFor={`traningtags[${row.id}]`}>
                                          {row.name}
                                          {row.tag_type === 'my_tag' && (
                                            <>
                                              {" - "}
                                              <span className="badge bg-success">My</span>
                                            </>
                                          )}
                                        </Label>
                                      </AvGroup>
                                    )}
                                  </div>
                                );
                                
                              })
                            )
                          }

                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-primary waves-effect waves-light" type="submit">Save</button>
                  </ModalFooter>
                </AvForm>
              </Card>
            </Col>
          </Row>
        </TabPane>

      </TabContent>
      </React.Fragment >
    )
  }
}

