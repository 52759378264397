import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import useToken, {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  isObjectEmpty,
  logout,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import Dropdownaction from "./Dropdown";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';

export default class AdminManagers extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          if (record.status != 'Abandoned') {
            return (
              this.state.action_reload && record.id != 3 && (
                <Dropdownaction userid={record.id} props={record.id} status={record.status} record={record} loginAsSubCoach={this.loginAsSubCoach} trigger_get_user={this.trigger_get_user} changeApprovalUser={this.changeApprovalUser} />
              )
            );
          }
        },
      },
      {
        text: "Name",
        key: "name",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_view"]) ? (
                <button
                  className="waves-effect btn-link px-0"
                  onClick={() => this.props.history.push("/user-profile/" + bcrypt(record.id))}
                >
                  {record.name}
                </button>
              ) : (
                record.name
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Phone",
        key: "phone",
      },
      {
        text: "Trust Hub Profile | Status",
        key: "business_profile",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.business_profile == "1" && (
                <Badge bg="success"> Approved </Badge>
              )}
              {record.business_profile == "0" && (
                <Badge bg="danger">(Not Approved)</Badge>
              )}
              {record.business_profile_type == "0" && (
                <Badge className="ml-5" bg="warning"> No profile </Badge>
              )}
              {record.business_profile_type == "1" && (
                <Badge className="ml-5" bg="info"> Starter  </Badge>
              )}
              {record.business_profile_type == "2" && (
                <Badge className="ml-5" bg="success"> Secondary</Badge>
              )}

            </Fragment>
          );
        },
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record.status == "Active" ? (
                  <Badge bg="success"> {record.status} </Badge>
                ) :
                  (record.status == "Abandoned" || record.status == "Pending") ? (
                    <Badge bg="warning"> {record.status} </Badge>
                  ) :
                    <Badge bg="danger"> {record.status} </Badge>
              }
            </Fragment>
          );
        },
      },
      {
        text: "Is Approved",
        key: "is_approved",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record.is_approved != "Yes" ? (
                  <button
                    disabled={record.status == 'Abandoned' ? true : false}
                    className="border-0 p-0 lh-1"
                    onClick={this.changeApprovalUser.bind(this, record, index)}
                  >
                    {record.is_approved == "No" && (
                      <Badge bg="warning"> {record.is_approved} </Badge>
                    )}

                    {record.is_approved == "Declined" && (

                      <Badge bg="danger"> {record.is_approved} </Badge>
                    )}
                  </button>
                ) : (
                  <Badge bg="success"> {record.is_approved} </Badge>
                )
              }
            </Fragment>
          );
        },

      },
      {
        text: "My Training",
        key: "_can_sell_trainings",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record._can_sell_trainings === "active_paid" ?
                  <div className={`d-flex justify-content-around align-items-center  if ${record._can_sell_trainings}`}>
                    <div className="form-check form-switch py-0 d-flex px-0 align-items-center">
                      <input type="checkbox" className="form-check-input m-0" id="customSwitch2" checked={true} onChange={e => {
                        this.coachCansellProducts(e.target.checked, record) // This will reload the page
                      }}
                      />
                    </div>
                    {/* <Badge bg="badge bg-success d-flex align-items-center"> Paid</Badge> */}
                  </div>
                  :
                  <div className={`d-flex justify-content-around align-items-center else ${record._can_sell_trainings}`}>
                    <div className="form-check form-switch py-0 d-flex px-0 align-items-center">
                      <input type="checkbox" className="form-check-input m-0" id="customSwitch2" checked={false} onChange={e => {
                        this.coachCansellProducts(e.target.checked, record) // This will reload the page
                      }}
                      />
                    </div>

                    {/* <Badge bg="secondary "> N/A </Badge> */}

                  </div>
              }
            </Fragment>
          );
        },

      },

      {
        text: "Created At",
        key: "created_at",
      },
    ];

    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      action_reload: true,
      records: [],
      coachPermission: {
        available_coach: 0,
        remaining_coach: 0,
      },
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "My Coaches",
      customDate: true,
      approvalModal: false,
      approvalUser: {},
      approvalStatus: '',
      packages: [],
      toggleSwitchpaid: false
    };
  }

  changeApprovalUser = (record, index) => {
    //console.log(record, 'recordrecordrecord')
    this.setState({
      approvalModal: true,
      approvalUser: record,
      approvalStatus: ''
    })
  }

  changeApprovalStatus = (status) => {
    let record = this.state.approvalUser;
    let msg = record.is_verification_status == 'Y' ? 'You want to perform this action' :
      `${'\n'} This user has not completed verification wizard. You want to perform this action`;

    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "approve/user/account/" + record.id + '/' + status, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                this.setState({
                  approvalUser: {},
                  approvalModal: false
                })
              } else {
                toast.error(data.message, { theme: "colored" });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({
            approvalUser: {},
            approvalModal: false
          })
        }
      });
  };


  coachCansellProducts = (status, record) => {

    let msg = status === true ? 'By enabling, the coach can add training, create training products, and generate invoices to sell their training to their clients.' : 'By disabling it, the coach will be unable to add training, create training products, and generate invoices to sell their training.';

    const status_record = status === true ? 'active_paid' : 'inactive';
    console.log(status)
    // console.log(record)


    //let msg = 'You want to perform this action';

    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "coach_can_sell_products/user/" + record.id + '/' + status_record, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();

              } else {
                toast.error(data.message, { theme: "colored" });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  deleteRecord = (record, index) => {
    fetch(`${ApiUrl}` + "delete/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: record.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();

          // toast.success(data.message, {
          //   theme: "colored",
          //   autoClose: ToastAutoClose,
          // });
        } else {
          toast.error(data.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidMount() {
    this.getData();
  }

  loginAsSubCoach = (user, users) => {
    logout();
    localStorage.setItem("authUser", JSON.stringify(user));
    localStorage.setItem("switchUser", window.btoa(encodeURIComponent(users)));
    setTimeout(this.props.history.push("/"), 4000);
  }
  trigger_get_user = () => {
    //console.log('trigger_get_user');

    this.getData();
  }

  getData = (queryString = "", data) => {
    this.setState({ action_reload: false })
    let body = { type: 'Coach' };
    if (data && !isObjectEmpty(data)) {
      body = data;
    }
    fetch(`${ApiUrl}` + "users" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
            coachPermission: response.data.coachPermission,
            packages: response.data.packages
          });
        } else {
          toast.error(response.message, { theme: "colored" });
        }
        this.setState({ action_reload: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    values.type = 'Coach';
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>
            {this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col sm={10}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">  {this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          {/* <Col sm={4}>
            <div className="page-title-box text-align-right">
              <h4> Available Limit : {this.state.coachPermission.available_coach}</h4>
            </div>
            <div className="page-title-box text-align-right">
              <h4>  Remaining Limit :  {this.state.coachPermission.remaining_coach}</h4>
            </div>
          </Col> */}
          <Col sm={2}>
            <div className="page-title-box text-align-right">
              {(isAllowed(uInfo(), ["can_create"])) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={"/users/admin/coach/add"}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}
            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col xl="12">
            <Card className="mb-0">
              <CardBody className="py-0">
                <AvForm
                  onValidSubmit={this.handleFilterSubmit}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="date_range"
                          label="Date Filter"
                          onChange={(e) =>
                            e.target.value == "Custom Date"
                              ? this.setState({
                                customDate: false,
                              })
                              : this.setState({
                                customDate: true,
                              })
                          }
                        >
                          <option value=""> All Time </option>
                          <option value="Current Week"> Current Week </option>
                          <option value="Last Week"> Last Week </option>
                          <option value="Current Month"> Current Month </option>
                          <option value="Last Month"> Last Month </option>
                          <option value="Last 3 Months"> Last 3 Months </option>
                          <option value="Last 6 Months"> Last 6 Months </option>
                          <option value="Current Year"> Current Year </option>
                          <option value="Last Year"> Last Year </option>
                          <option value="Custom Date"> Custom Date </option>
                        </AvField>
                      </div>
                    </Col>
                    <Col md="2" className="px-0">
                      <div className="mb-3">
                        <AvField
                          name="start_date"
                          label="Start date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          id="start_date"
                          isClear={true}
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                        <AvInput type='hidden' name="type" value="Coach" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          name="end_date"
                          label="End date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="1" className="px-0">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          id="s"
                          name="status"
                          label="Status"
                        >
                          <option value={""}> Select Status </option>
                          <option value={"Pending"}> Pending </option>
                          <option value={"Active"}> Active </option>
                          <option value={"Inactive"}> Inactive </option>
                          <option value={"Abandoned"}> Abandoned </option>
                          {/* <option value={"Suspended"}> Suspended </option> */}
                          <option value={"Expired"}> Expired </option>
                          <option value={"Canceled"}> Canceled </option>
                          {/* <option value={"System-Inactive"}> System-Inactive </option> */}
                        </AvField>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="is_approved"
                          label="Approval Status"
                        >
                          <option value={""}> Select Status </option>
                          <option value={"No"}> No </option>
                          <option value={"Yes"}> Yes </option>
                          <option value={"Declined"}> Declined </option>
                        </AvField>
                      </div>
                    </Col>

                    <Col md="2" className="ps-0">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="package_id"
                          label="Coach Level"
                        >
                          <option value={""}> Select Level </option>
                          <optgroup label="Default">
                            {
                              this.state.packages.map((row, i) => {
                                if (row.package_type == 'default') {
                                  return <option value={row.package_id}> {row.name}</option>
                                }
                              })
                            }
                          </optgroup>
                          <optgroup label="Custom">
                            {
                              this.state.packages.map((row, i) => {
                                if (row.package_type == 'custom') {
                                  return <option value={row.package_id}> {row.name}</option>
                                }
                              })
                            }
                          </optgroup>
                        </AvField>
                      </div>
                    </Col>

                    <Col md="1" className="px-0">
                      <label className="">Action</label>
                      <div className="mb-3">
                        <div className="button-items">
                          <Button
                            className="btn-sm"
                            color="primary"
                            type="submit"
                          >
                            Filter
                          </Button>
                          <Button
                            onClick={() => this.cancelFilter()}
                            outline
                            color="secondary"
                            className="waves-effect btn-sm"
                            type="button"
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.approvalModal}
          fade={false}
          toggle={() => this.setState({ approvalModal: false })}
        >
          <ModalBody>
            <div className="form-group mb-3">
              <label>Select Status</label>
              <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="from_number" className="form-control" >
                <option value={''}>Select option</option>
                <option value={'No'}>No</option>
                <option value={'Yes'}>Yes</option>
                <option value={'Declined'}>Declined</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            {
              this.state.approvalStatus != '' ?
                <Button color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                  Yes, Change it
                </Button>
                :
                <Button disabled color="info" >
                  Yes, Change it
                </Button>
            }
            <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pt-0">
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  key={this.state.id}
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  }
}
