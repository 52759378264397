import React, { useState, useCallback,useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin,Navigation } from '@react-pdf-viewer/default-layout';
import { useLocation } from 'react-router-dom';
import { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { ApiUrl, AttachementsUrl, FroalaLicenseKey,HostUrl } from '../../config';
import { useHistory  } from 'react-router-dom';
import { bcrypt, uInfo, uToken,dcrypt } from '../../useToken';



import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';


const PdfStatic = (props) => {

    
    const history = useHistory();

    const location = useLocation();
    const segments = location.pathname.split('/').filter(Boolean); // Split and filter empty strings
    // console.log(dcrypt(segments[2]));
    
    // segments.shift();
    // segments.shift();
    // segments.shift();
    // const url = segments.join('/');

    const url = dcrypt(segments[2]);

    React.useEffect(() => {
        if (!(window.self !== window.top)) {
            history.push('/pages-404');
        }
      }, []);
  

  const transform = (slot) => ({
    
    ...slot,    
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Print:()=><></>,
    // EnterFullScreen: () => <></>,
    // EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    Open: () => <></>,
}
    
);


const renderToolbar = (Toolbar) => (
   
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
);
const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
});

const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;


  return (
    
      <div className="App">
            <div style={{ height: '1080px' }}>
            {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}> */}

                <Worker workerUrl={`${HostUrl}/pdfJs_worker.min.js`}>
                    <Viewer 
                    fileUrl={url} 
                    plugins={[defaultLayoutPluginInstance]} 
                    
                    />
            </Worker>
          </div>
          
      </div>
     
  );
};
export default PdfStatic;

