import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Select from "react-select";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export default class AdminTagGroup extends Component {
    constructor(props) {
        super(props);
        // const params = new URLSearchParams(this.props.location.search);
        const id = "";//params.get('id');
        this.state = {
             id: null ,//id? dcrypt(id): null,
            records: [],
            record: {},
            trainings: [],
            associated_trainings: [],
            users: [],
            associated_users: [],
            roles: [],
            total_pages: 0,
          
            filter: {},
            filter_user: {},
            page_name: " Training Tags List",
            customDate: true,
            modal_4: false,

            selectedIds: [],
            selectAll: false,
            selectedIdsAssociated: [],
            selectAllAssociated: false,
            selectedUserIds: [],
            selectAllUsers: false,
            selectedUserIdsAssociated: [],
            selectAllAssociatedUserIds: false,
            selected_users:[],
            selected_associated_users:[],
            show_user: false,
            show_associated_user: false,
            all_option_users: "All Users",
            all_option_associated_users: "All Users",
            categories:[],
            subjects:[],
            show_subject:false,
            documents:[],
            show_document:false,
            inputValue:[],
            setListTags:[],           
            show_associated_subject:false,
            show_associated_document:false,
            trainingtagonly:[],
            list_tags:[],
            selectedTags:[],
            
        };
      
        this.columns = [
            {
                text: "#",
                key: "sr",
                sortable: false,
            },
            {
                text: "Group Name",
                key: "name",
                sortable: false,
            },
            {
                text: "Associated Tag(s)",
                key: "trainings_name",
                cell: (record, index) => {
                
                    return <span>{record.tag_names.join(' , ')}</span>;
                }
            },
            
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {isAllowed(uInfo(), ["can_edit"]) && (
                                <>
                                    
                                    <button
                                        onClick={() =>
                                           {
                                           
                                            this.setState({
                                                modal_4: true,
                                                record: record,
                                             
                                            });
                                            this.getmeta(record.id);
                                           
                                            
                                           }
                                        }
                                        className="btn btn-primary btn-sm mr-5"
                                    >
                                        <i className="mdi mdi-pencil"></i>
                                    </button>
                                    
                                        <button
                                            className="btn btn-danger btn-sm mr-5"
                                            onClick={this.deleteRecord.bind(this, record, index)}
                                        >
                                            <i className="mdi mdi-trash-can-outline"></i>
                                        </button>

                                                                     
                                                                              
                                </>
                            )}
                        </Fragment>

                    );
                },
            },
        ];
        this.config = {
            key_column: "sr",
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: false,
            show_info: false,
            show_length_menu: false,           

            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
     
    }

 

  
 
    componentDidMount() {
        this.gettrainingonly();
        this.getData();

        if(this.state.id && !this.state.modal_4){
            this.setState({
                modal_4: true,
                record: {}
            });
        }
        // // this.getmeta(this.state.id);
        // this.gettrainingonly();
        

    }
    handleSelect = (key) => {
        if (key === 'users') {
            this.fetchRecord("", "users"); 
            this.getAssociatedRecord(this.state.id, "", "users");
        }
    };

    handleActionChange = async (key, value, type) => {
   
        let api_uri = ApiUrl + "get/users";
        let data = [];
        if(type === 'users'){
            this.setState({show_user: value !== ''? true:false});
        }
   
    
      
        let queryString = data.map(obj => {
        return Object.keys(obj).map(key => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        }).join("&");

        }).join("&");
            fetch(`${api_uri}` + "?" + queryString, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({}),
            
            })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    if(type == 'users'){
                        this.setState({
                            selected_users : response.data.records,
                        })
                    }
                    if(type == 'associated_users'){
                        this.setState({
                            selected_associated_users : response.data.records,
                        })
                    }
                    if(type == 'categories'){
                        this.setState({
                            categories : response.data.record,
                        })
                    }
                    if(type == 'subjects' || type == 'associated_subjects'){
                        this.setState({
                            subjects : response.data.records,
                        })
                    }
                    if(type == 'documents' || type == 'associated_documents'){
                        this.setState({
                            documents : response.data.records,
                        })
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

   
    
    

    };


    changestatus = (record, index) => {
        swal({
          title: "Are you sure?",
          text: "You want to Update the status.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then((confirmation) => {
            if (confirmation) {
              fetch(`${ApiUrl}` + 'trainingtag/changestatus', { 
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + uToken()
                },
                body: JSON.stringify({ 'id': record.id }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.status === true) {
                    this.getData();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                  } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                  }
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }
          });
    
    }

    deleteRecord = (record, index) => {
        swal({
          title: "Are you sure?",
          text: "You want to remove this record.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then((confirmation) => {
            if (confirmation) {
              fetch(`${ApiUrl}` + 'delete/traininggroups', { 
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + uToken()
                },
                body: JSON.stringify({ 'id': record.id }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.status === true) {
                    this.getData();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                  } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                  }
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }
          });
    
    }
    handleFilterSubmit = async (event, values) => {
        this.setState({
          filter: values,
        });
        this.getData(this.props.match.params.type, values);
      };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/getTrainingTagGroup" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                        modal_4 : false,

                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getmeta = (id) => {
        fetch(`${ApiUrl}` + "get/training/metagroup", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id}),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        id: response.data.record.id,

                        list_tags:response.data.tags,
                        selectedTags: response.data.tag_ids,
                        // selectedTags:response.data.tag_ids

                        
                    });
                    // this.fetchRecord("", "trainings");
                    // this.getAssociatedRecord(response.data.record.id, "", "trainings");
                    // this.fetchRecord("", "users");
                    // this.getAssociatedRecord(response.data.record.id, "", "users");
                    // this.handleActionChange('', '','categories');
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleInputChange = (newValue) => {

        this.setState({
            inputValue:newValue,

        });
		};





    fetchRecord = (queryString="", type = "trainings") => {
        fetch(`${ApiUrl}` + `get/${type}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id}),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {

                    if(type === 'users'){
                        this.setState({
                            users: response.data.records,
                        });
                    }
                    if(type === 'trainings'){
                        this.setState({
                            trainings: response.data.records,
                        });
                    }
                } 
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
   
    getAssociatedRecord = (id=null, queryString="", type = "trainings") => {
        fetch(`${ApiUrl}` + `get/associated/${type}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id}),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    if(type === 'users'){
                        this.setState({
                            associated_users: response.data.records,
                        });
                    }
                    if(type === 'trainings'){
                        this.setState({
                            associated_trainings: response.data.records,
                        });
                    }
                    
                } 
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
   
    handleFilter = async (event, data) => {
         let type = (data.type)?(data.type):"trainings";
         
        let queryString = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }).join("&");
        this.fetchRecord("?" + queryString, type);
    };
 
    handleFilterAssociated = async (event, data) => {
        let type = (data.type)?(data.type):"trainings";
        let queryString = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }).join("&");
        this.getAssociatedRecord(this.state.id,"?" + queryString, type);
    };
    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    searchFilter = () => {

    }

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

 
  

    handleSubmit= async (event, values) => {
        fetch(`${ApiUrl}` + "add/training/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        modal_1: false,
                    });
                    this.getData();
                    
                    this.getmeta(response.data.record.id);
                    this.setState({
                        modal_2: true,
                    });
                  
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSelectAll = (event, type) => {
        const isChecked = event.target.checked;
        if(['associated_trainings', 'associated_users'].includes(type)){
            if (type =='associated_users') {
                this.setState({
                    selectAllAssociatedUserIds: isChecked,
                    selectedUserIdsAssociated: isChecked
                        ? this.state.associated_users.map(training => training.id)
                        : [],
                    selectAllUsers: false,
                    selectedUserIds: [],
                });  
            }else{
                this.setState({
                    selectAllAssociated: isChecked,
                    selectedIdsAssociated: isChecked
                        ? this.state.associated_trainings.map(user => user.id)
                        : [],
                    selectAll: false,
                    selectedIds: [],
                }); 
            }
        }else{
            if (type =='users') {
                this.setState({
                    selectAllUsers: isChecked,
                    selectedUserIds: isChecked
                        ? this.state.users.map(user => user.id)
                        : [],
                    selectAllAssociatedUserIds: false,
                    selectedUserIdsAssociated: [],
                });
            }else{
                this.setState({
                    selectAll: isChecked,
                    selectedIds: isChecked
                        ? this.state.trainings.map(training => training.id)
                        : [],
                    selectAllAssociated: false,
                    selectedIdsAssociated: [],
                });
            }
           
        }
    };

    handleCheckboxChange = (id, type) => {
        if(['associated_trainings', 'associated_users'].includes(type)){
            if (type =='associated_users') {
                this.setState(prevState => {
                    const { selectedUserIdsAssociated } = prevState;
                    const newSelectedIds = selectedUserIdsAssociated.includes(id)
                        ? selectedUserIdsAssociated.filter(selectedId => selectedId !== id)
                        : [...selectedUserIdsAssociated, id];
                    return {
                        selectedUserIdsAssociated: newSelectedIds,
                        selectAllAssociatedUserIds: newSelectedIds.length === this.state.associated_users.length,
                    };
                });
            }else{
                this.setState(prevState => {
                    const { selectedIdsAssociated } = prevState;
                    const newSelectedIds = selectedIdsAssociated.includes(id)
                        ? selectedIdsAssociated.filter(selectedId => selectedId !== id)
                        : [...selectedIdsAssociated, id];
                    return {
                        selectedIdsAssociated: newSelectedIds,
                        selectAllAssociated: newSelectedIds.length === this.state.associated_trainings.length,
                    };
                });
            }
        }else{
            if (type =='users') {
                this.setState(prevState => {
                    const { selectedUserIds } = prevState;
                    const newSelectedIds = selectedUserIds.includes(id)
                        ? selectedUserIds.filter(selectedId => selectedId !== id)
                        : [...selectedUserIds, id];
                    return {
                        selectedUserIds: newSelectedIds,
                        selectAllUsers: newSelectedIds.length === this.state.users.length,
                    };
                });
            }else{
                this.setState(prevState => {
                    const { selectedIds } = prevState;
                    const newSelectedIds = selectedIds.includes(id)
                        ? selectedIds.filter(selectedId => selectedId !== id)
                        : [...selectedIds, id];
                    return {
                        selectedIds: newSelectedIds,
                        selectAll: newSelectedIds.length === this.state.trainings.length,
                    };
                });
            }
        }
    };
    handleLinkClick = (action_type) => {
        const formData = new FormData();
        formData.append('tag_id', this.state.id);
        let ids;

        switch (action_type) {
            case 'associated_users':
                ids = this.state.selectedUserIdsAssociated;
                break;
            case 'users':
                ids = this.state.selectedUserIds;
                break;
            case 'associated_trainings':
                ids = this.state.selectedIdsAssociated;
                break;
            default:
                ids = this.state.selectedIds;
        }
        
        formData.append('ids', ids);
        formData.append('action_type', action_type)
      
        fetch(`${ApiUrl}` + `handle/trainingswitch/action`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ` + uToken(),
            },
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    if(['trainings', 'associated_trainings'].includes(action_type)){
                        
                        this.setState({
                            selectAllAssociated: false,
                            selectedIdsAssociated: [],
                            selectAll: false,
                            selectedIds: [],
                        });
                        if(action_type == 'trainings'){
                            if (this.state.show_document) {
                                this.setState({
                                    show_associated_subject: true,
                                    show_associated_document: true,
                                    current_assoc_cat_id: this.state.current_cat_id,
                                    current_assoc_subject_id: this.state.current_subject_id,
                                    current_assoc_document_id: this.state.current_document_id,
                                });
                          
                                let params_array = [ 
                                    {type: "trainings"},
                                    {cat_id: this.state.current_cat_id}, 
                                    {subject_id: this.state.current_subject_id},
                                    {document_id: this.state.current_document_id}
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                  }).join("&");
                                
                                this.getAssociatedRecord(this.state.id,"?" + queryString, "trainings");
                                this.fetchRecord("?" + queryString, "trainings");
                            }else{
                                this.getAssociatedRecord(this.state.id, "", "trainings");
                                this.fetchRecord("", "trainings");
                            }
                        }
                        if(action_type == 'associated_trainings'){
                            if (this.state.current_assoc_document_id) {
                                this.setState({
                                    show_subject: true,
                                    show_document: true,
                                    current_cat_id: this.state.current_assoc_cat_id,
                                    current_subject_id: this.state.current_assoc_subject_id,
                                    current_document_id: this.state.current_assoc_document_id,
                                });
                          
                                let params_array = [ 
                                    {type: "trainings"},
                                    {cat_id: this.state.current_assoc_cat_id}, 
                                    {subject_id: this.state.current_assoc_subject_id},
                                    {document_id: this.state.current_assoc_document_id}
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                  }).join("&");
                                
                                this.fetchRecord("?" + queryString, "trainings");
                                this.getAssociatedRecord(this.state.id, "?" + queryString, "trainings");
                            }else{
                                this.getAssociatedRecord(this.state.id, "", "trainings");
                                this.fetchRecord("", "trainings");
                            }
                        }
                        
                    }
                    if(['users', 'associated_users'].includes(action_type)){
                 
                        this.setState({
                            selectAllAssociatedUserIds: false,
                            selectedUserIdsAssociated: [],
                            selectAllUsers: false,
                            selectedUserIds: [],
                        });  
                        if(action_type == 'users'){
                            if (this.state.show_user) {
                                this.setState({
                                    show_associated_user: true,
                                    current_assoc_user_id: this.state.current_user_id,
                                    current_assoc_user_type: this.state.current_user_type,
                                });
                          
                                let params_array = [ 
                                    {type: "users"},
                                    {master_id: this.state.current_user_id}, 
                                    {user_type: this.state.current_user_type},
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                  }).join("&");
                                
                                this.getAssociatedRecord(this.state.id,"?" + queryString, "users");
                                this.fetchRecord("?" + queryString, "users");
                            }else{
                                this.getAssociatedRecord(this.state.id, "", "users");
                                this.fetchRecord("", "users");
                            }
                        }
                        if(action_type == 'associated_users'){
                            if (this.state.show_associated_user) {
                                this.setState({
                                    show_user: true,
                                    current_user_id: this.state.current_assoc_user_id,
                                    current_user_type: this.state.current_assoc_user_type,
                                });
                          
                                let params_array = [ 
                                    {type: "users"},
                                    {master_id: this.state.current_assoc_user_id}, 
                                    {user_type: this.state.current_assoc_user_type},
                                ]
                                let queryString = params_array.map(obj => {
                                    return Object.keys(obj).map(key => {
                                        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
                                    }).join("&");
                                  }).join("&");
                                
                                this.getAssociatedRecord(this.state.id,"?" + queryString, "users");
                                this.fetchRecord("?" + queryString, "users");
                            }else{
                                this.getAssociatedRecord(this.state.id, "", "users");
                                this.fetchRecord("", "users");
                            }
                        }
                    }
                }else{
                    swal({
                        text: response.message,
                        icon: "warning",
                      });
                      return false;
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    gettrainingonly = () => {
        fetch(`${ApiUrl}` + "get/trainingTagOnly", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            // body: JSON.stringify({ id: id}),
           
        })
            .then((response) => response.json())
            .then((response) => {
                
                if (response.status === true) {
                    const globalTags = response.data.records && response.data.records.filter(tag => tag.user_id === 2);
                    const myTags = response.data.records && response.data.records.filter(tag => tag.user_id !== 2);
                    
                    // Declare groupedOptions outside of the if/else blocks
                    let groupedOptions;
                    
                    if (uInfo().user_id === 2) {
                        groupedOptions = [
                            {
                                label: 'Select Tags',
                                options: globalTags
                            }
                        ];
                    } else {
                        groupedOptions = [
                            {
                                label: 'My Tags',
                                options: myTags
                            },
                            {
                                label: 'Global Tags',
                                options: globalTags
                            }
                        ];
                    }
                    
                    // Use the groupedOptions variable in setState
                    this.setState({
                        trainingtagonly: response.data.records,
                        groupedOptions: groupedOptions,
                    });
                    

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleChange = (selectedOptions) => {
        // const selectedIds = selectedOptions.map((option) => option.id);

        this.setState({
            // settagids : selectedIds,    
            inputValue : selectedOptions,                
        
        });
        // console.log('parameter sent',selectedOptions);

        console.log('input state',this.state.inputValue);


      };

      handlegroupSubmit= async (event, values) => {
        const selectedIds = this.state.selectedTags.map((option) => option.id);
        console.log('ids in submit func', selectedIds);
        values.tag_select = selectedIds;
        values.id =  this.state.id;

        fetch(`${ApiUrl}` + "edit/training/groups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                  
                    this.getData();
                    
                    // this.getmeta(response.data.record.id);
                    // this.setState({
                    //     modal_3: true,
                    // });
                  
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handlegroupSubmitAdd= async (event, values) => {
        const selectedIds = this.state.inputValue.map((option) => option.id);
        console.log('ids in submit func', selectedIds);
        values.tag_select = selectedIds;

        fetch(`${ApiUrl}` + "add/training/groups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                  
                    this.getData(); 
                    this.form && this.form.reset();
                    this.setState({ inputValue: [] }); 
                  
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleTag = (selectedTags) => {
        var filter = [];
        if (selectedTags) {
          selectedTags.map((fil) => {
            filter.push(fil.id);
          });
          
        }
        this.setState({
            tag_id: filter.toString(),
            selectedTags: selectedTags,
          });
    };

    
    render() {
        return (
            <ErrorBoundary>
               
                <ToastContainer />


               
                                <AvForm
                                    onValidSubmit={this.handlegroupSubmitAdd}
                                    className="needs-validation"
                                    ref={(c) => (this.form = c)}  
                                >
                                    {
                                        <>
                                        <div className="mb-3">
                                        <AvField
                                            name="name"
                                            label="Training Group"
                                            className="form-control"
                                            placeholder="Training Group"
                                            type="text"
                                        />
                                        </div>

                                        <div className="mb-3 col-lg-12">
										<label htmlFor="training_for-text-label" className="form-label">Tags</label>
										<div className='LowIndexothers ActionOne'>
                                        <Select
                                             getOptionLabel={(option) => option.name}
                                             getOptionValue={(option) => option.id}
                                            isMulti={true}
                                            options={this.state.groupedOptions}
                                            onChange={this.handleChange}
                                            value={this.state.inputValue}
                                            classNamePrefix="select2-selection position-relative"
                                            name="tags[]"
                                        />
                                        </div>                                        
                                        </div>
                                        <div className="mb-3 col-lg-12">

                                    <button
                                      className="btn btn-primary waves-effect waves-light"
                                      type="submit"
                                  >
                                      Create
                                         </button>
                                        </div>
                                        </>
                                    }
                                </AvForm>
                                    
                            

                
                 
                  <Row>
                      <Col className="col-12">
                          <Card>
                              <CardBody className="pt-0">
                              
                                  <ReactDatatable
                                      id={''}
                                      key={this.state.id}
                                      config={this.config}
                                      records={this.state.records}
                                      columns={this.columns}
                                      dynamic={true}
                                      total_record={this.state.total_pages}
                                      onChange={this.tableChangeHandler}
                                  />
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
            

 {/* modal 4 start */}
                <Modal
                      isOpen={this.state.modal_4}
                      role="dialog"
                      centered={true}
                      className="exampleModal TagsPopup"
                      tabIndex="-1"
                      toggle={() => {
                          this.setState({
                              modal_4: !this.state.modal_4,
                              record: {}
                          });
                      }}
                  >
                      <div className="modal-content">
                          <ModalHeader
                              toggle={() => {
                                  this.setState({
                                      modal_4: !this.state.modal_4,
                                      record: {}
                                  });
                              }}
                              className="text-transform-capitalize"
                          >
                              {'Update Training Group'}
                          </ModalHeader>
                       
                          
                                <ModalBody>
                                <AvForm
                                    onValidSubmit={this.handlegroupSubmit}
                                    className="needs-validation"
                                >
                                    {
                                        <>
                                        <div className="mb-0">
                                        <AvField
                                            name="name"
                                            label="Training Group"
                                            className="form-control"
                                            placeholder="Training Group"
                                            type="text"
                                            errorMessage="Name is required"
                                            validate={{
                                                required: { value: true },                                              
                                              }}
                                            value = {this.state.record.name}
                                        />
                                        </div>

                                        <div className="mb-3 col-lg-12">
										<label htmlFor="training_for-text-label" className="form-label">Tags</label>
										<div className='LowIndexothers ActionOne'>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isMulti={true}
                                            required
                                            value={this.state.selectedTags}
                                            options={this.state.groupedOptions || []} // Simplified condition
                                            classNamePrefix="select2-selection position-relative"
                                            name="tags[]"
                                            onChange={(e) => {
                                                this.handleTag(e);
                                              }}

                                        />
                                        </div>                                        
                                        </div>
                                        <div className="mb-3 col-lg-12">

                                    <button
                                      className="btn btn-primary waves-effect waves-light"
                                      type="submit"
                                  >
                                      Update
                                         </button>
                                        </div>
                                        </>
                                    }
                                </AvForm>
                                    
                                </ModalBody>                           
                        
                          
                      </div>
                </Modal>

            </ErrorBoundary >
        );
    }
}
