import React, { Component } from "react";
import $ from "jquery";
import Froala from "../Froala";
import EmailSideBar from "./email-sidebar";
import Select from 'react-select';
import { toast, Flip } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    bcrypt,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl } from "../../config";
import {
    Row,
    Col,
    Card,
    Button,
    CardBody
} from "reactstrap";

class EmailCompose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            existing: [],
            isClient: true,
            selectedEmail: '',
            show: false,
            verifiedFromEmail: [],
            defaultFromEmail: null,
            verifiedFromEmailId: '',
            smsTemplates: [],
            tokens: [],
            subject: '',
            smsBody: '',
            token: '',
            selectedClient: {},
            html: '',
            folder_Menu: false,
            tag_Menu: false,
            more_Menu: false,
            showAlert: false,
            alertType: '',
            alertTitle: '',
            alertText: ''
        };

    }

    componentDidMount() {
        this.getData();
        this.getFormData();
        this.getEmailTemplate();
        this.getVerifiedFromEmail();
    }

    handleBody = async (event, values) => {
        console.log('Values:', values);
        this.setState({
            smsBody: values,
        });
    }


    handleEmailSubmit = (event, values) => {
        event.preventDefault();

        values.tos = this.state.selectedClient.email;
        values.client_id = this.state.selectedClient.client_id;
        values.type = 'email';
        values.setting_id = this.state.verifiedFromEmailId;
        console.log(values);

        if (this.state.html !== "") {
            let body = document.getElementById("custom-template").innerHTML;
            values.body = body;
        } else {
            values.body = this.state.smsBody;
        }


        fetch(`${ApiUrl}company/email/activity`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        showAlert: true,
                        alertType: 'success',
                        alertTitle: 'Success',
                        alertText: 'Email sent successfully.',
                    });
                    setTimeout(() => {
                        this.props.history.push('/email/sent');
                    }, 2000);
                } else {
                    this.setState({
                        showAlert: true,
                        alertType: 'error',
                        alertTitle: 'Error',
                        alertText: data.message,
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                this.setState({
                    showAlert: true,
                    alertType: 'error',
                    alertTitle: 'Error',
                    alertText: 'An error occurred while sending Email.',
                });
            });
    };


    searchClient = (search) => {
        if (search !== "") {
            fetch(`${ApiUrl}` + "get/clients", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ filter_value: search }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            existing: response.data.records,
                            isClient: response.data.records.length > 0,
                        });
                    } else {
                        toast(response.message, {
                            hideProgressBar: true,
                            position: "top-center",
                            transition: Flip,
                            className: 'toast-custom-cls error',
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            this.setState({
                existing: [],
                isClient: true,
            });
        }
    };


    getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState({ html: response.theme })
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getFormData = () => {
        fetch(`${ApiUrl}get/quick/dialer`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        quickDialer: response.data.records,
                        phoneNumbers: response.data.phoneNumbers,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getData = (queryString = "", data) => {
        const query = queryString === "" ? "?lead_type=client&linked_user_id=0" : queryString + "&lead_type=client";
        fetch(`${ApiUrl}leads${query}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        customFieldData: { client_ids: [] },
                        coachPartners: response.data.coach_partners
                    });
                    $('input[type="checkbox"]:checked').prop('checked', false);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };



    getSmsTemplate = () => {
        fetch(`${ApiUrl}get/sms/template`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const templates = this.groupArrayOfObjects(data.data.templates, "type");
                    const templates_group = [
                        { label: "SMS", options: templates.SMS },
                        { label: "MMS", options: templates.MMS },
                    ];

                    const tokens_group = [
                        {
                            label: "Default Field",
                            options: data.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: data.data.tokens.custom_fields,
                        },
                    ];

                    this.setState({
                        smsTemplates: templates_group,
                        tokens: tokens_group,
                        phoneNumbers: data.data.phoneNumbers
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getEmailTemplate = () => {
        fetch(`${ApiUrl}get/email/template`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const tokens_group = [
                        {
                            label: "Default Field",
                            options: data.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: data.data.tokens.custom_fields,
                        },
                    ];

                    this.setState({
                        tokens: tokens_group,
                        smsTemplates: data.data.templates
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    getVerifiedFromEmail = () => {
        fetch(`${ApiUrl}verify/email/list`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    const defaultFromEmail = data.data.records.filter(item => item.is_default === 1);
                    this.setState({
                        verifiedFromEmail: data.data.records,
                        defaultFromEmail: defaultFromEmail,
                        verifiedFromEmailId: defaultFromEmail[0]?.setting_id
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    groupArrayOfObjects = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});
    };

    toggleSearch = () => {
        this.setState((prevState) => ({ isSearch: !prevState.isSearch }));
    };

    toggleShow = () => {
        this.setState((prevState) => ({ show: !prevState.show }));
    };
    handleSelectClient = (row) => {
        this.setState({
            selectedClient: row,
            existing: []
        }, () => {
            console.log('State updated:', this.state); // Log the updated state
        });
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({ selectedClient: value }, () => {
            this.searchClient(value);
        });
    }

    handleAlertConfirm = () => {
        this.setState({ showAlert: false });
    };
    render() {
        const { selectedClient, existing, isClient, showAlert, alertType, alertTitle, alertText } = this.state;
        return (

            <React.Fragment>
                <style>
                    {`.froala #edit { padding: 0px;  }`}
                </style>
                <Row>
                    <div>
                        {showAlert && (
                            <SweetAlert
                                type={alertType}
                                title={alertTitle}
                                onConfirm={this.handleAlertConfirm}
                            >
                                {alertText}
                            </SweetAlert>
                        )}
                    </div>

                    <Col xs="12">
                        <EmailSideBar />

                        <div className="email-rightbar mb-3">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={this.handleEmailSubmit} autoComplete="off" className="needs-validation">
                                        <div className="col-md-12 mb-2">
                                            <div className="MainTZone ClientDetails Two 6">
                                                <label>From Email</label>
                                                <Select
                                                    getOptionLabel={(option) => `${option.from_name} - ${option.from_email} - ${option.type}`}
                                                    getOptionValue={(option) => option.setting_id}
                                                    isMulti={false}
                                                    options={this.state.verifiedFromEmail}
                                                    classNamePrefix="select2-selection"
                                                    placeholder={"Select From Email"}
                                                    value={this.state.defaultFromEmail}
                                                    name="froms"
                                                    onChange={(option) => {
                                                        this.setState({
                                                            defaultFromEmail: option,
                                                            verifiedFromEmailId: option.setting_id
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-4 dropdown SearchDropDown">
                                            <div className="app-search d-inline-block search" style={{ paddingBottom: '0px' }}>

                                                <label>To Email</label>
                                                <div className="position-relative d-flex">
                                                    <input
                                                        style={{ borderColor: 'hsl(0, 0%, 80%)', borderWidth: '1px', borderStyle: 'solid' }}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={selectedClient.email}
                                                        onChange={this.handleInputChange}
                                                        onBlur={() =>
                                                            setTimeout(() => {
                                                                this.setState({ existing: [] });
                                                            }, 1000)
                                                        }
                                                    />

                                                </div>

                                                <ul style={{ position: 'static' }} className="client-search-bar">
                                                    {!isClient && <li className="text-primary">No data found</li>}
                                                    {existing.map((row, i) => (
                                                        <li key={i} onClick={() => this.handleSelectClient(row)}>
                                                            <div className="d-grid-template">
                                                                <div className="info">
                                                                    <i className="fas fa-user"></i>
                                                                    {row.first_name} {row.last_name}
                                                                </div>

                                                                <div className="info">
                                                                    <i className="fas fa-mobile"></i>
                                                                    {row.mobile}
                                                                </div>
                                                            </div>

                                                            <div className="d-grid-template">
                                                                <div className="info">
                                                                    <i className="fas fa-user"></i> {row.email}
                                                                </div>

                                                                <div className="info">
                                                                    <i className="fas fa-phone"></i>
                                                                    {row.phone}
                                                                </div>

                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div className="mb-3">
                                            <div className="CommonSelectTwo Zindex">

                                                <Select
                                                    getOptionLabel={(option) => option.title}
                                                    getOptionValue={(option) => option.id}
                                                    // value={options.filter(({ id }) => id === this.state.country_code)}
                                                    isMulti={false}
                                                    options={this.state.smsTemplates}
                                                    classNamePrefix="select2-selection"

                                                    name="country_id"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            subject: e.title
                                                        })
                                                        this.getEmailTemplateHtml(e.id);


                                                    }}
                                                />


                                                <AvField type="hidden" name="activity" value={"Email: sent"} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12 mb-3">
                                                <div className="CommonSelectTwo 1">
                                                    <Select
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.key}
                                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                                        isMulti={false}
                                                        options={this.state.tokens}
                                                        classNamePrefix="select2-selection"
                                                        name="country_id"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                token: `{${e.key}}`
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {/* <AvField type="hidden" name="country_code" value={token} /> */}
                                            </div>

                                            {this.state.html == "" && (
                                                <div className="col-md-2">
                                                    <button
                                                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                                                        type="button"
                                                        className="btn btn-primary"
                                                    >
                                                        Insert Body
                                                    </button>
                                                </div>
                                            )}
                                            <div className="col-md-4">
                                                <button
                                                    onClick={() => this.setState({ subject: `${this.state.subject} ${this.state.token}` })}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Insert Subject
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                className="form-control"
                                                type="text"
                                                name="subject"
                                                placeholder="Subject"
                                                required
                                                value={this.state.subject}
                                                onChange={(e) => this.setState({ subject: e.target.value })}
                                            />
                                        </div>
                                        {this.state.html == "" ? (
                                            <div className="mb-3">
                                                {/* <AvField
                                                    className="form-control"
                                                    type="textarea"
                                                    name="body"
                                                    placeholder="Enter body"
                                                    required
                                                    value={this.state.smsBody}
                                                    onChange={(e) => this.setState({ smsBody: e.target.value })}
                                                /> */}

                                                <Froala
                                                    btnId={'add-script-content'}
                                                    handleSubmit={this.handleBody}
                                                    use_token={'users'}

                                                />

                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    id="custom-template"
                                                    className="height-500 custom-scroll"
                                                    dangerouslySetInnerHTML={{ __html: this.state.html }}
                                                ></div>
                                                {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                                            </>
                                        )}

                                        <div className="mt-3">
                                            <Button color="info" id="add-script-content" type="submit">
                                                Send
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default EmailCompose;