import React, { useState, useEffect } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { ApiUrl } from "../../config";
import { uToken, bcrypt } from "../../useToken";
import { useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    CardTitle,
} from "reactstrap";

import swal from "sweetalert";


const FromEmailTabel = () => {
    const history = useHistory();
    const [records, setRecords] = useState([]);
    const [setLoaded, setShowLoaded] = useState(false);
    const [showCode, setShowCode] = useState(false);
    // console.log(records);

    const [memberData, setMemberData] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState('');
    const [getAssignRecord, setAssignRecords] = useState([]);
    const [modal, setModal] = useState(false);


    const [assignid, setAssignId] = useState();

    // console.log("getAssignRecord", selectedMembers);
    // console.log("getmember" , memberData);


    // Function to toggle the modal
    const toggleModal = (id) => {
        setModal(!modal);
        setAssignId(id);

    };
    const config = {
        show_pagination: false,
        show_search: false,
        show_length_menu: false,
        show_filter: false,
    };



    useEffect(() => {
        getData();
        assignData();
        getAssignData();
    }, []);

    const getData = () => {
        fetch(`${ApiUrl}list/from/emails`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setRecords(response.data.records);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    //assign team Member
    const assignData = (queryString = "?all=1", data) => {
        fetch(`${ApiUrl}` + "coachpartners" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    const activeMembers = response.data.records.filter(record => record.status === "Active");
                    setMemberData(activeMembers);
                    setShowLoaded(true);
                    // console.log(response, "Coach Partner data");

                } else {

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const verifySetting = (id) => {
        fetch(`${ApiUrl}verify/from/email`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then((data) => {


                if (data.status === true) {
                    var msg = 'An activation link has been sent to your email. Please first open the link received in your email and then click the refresh button below to check your sender ID status.';
                    setShowCode(false);
                    setShowLoaded(true);
                    swal({
                        title: "Success!",
                        text: msg,
                        icon: "success"
                    });
                    getData();
                } else {
                    swal({
                        title: "error",
                        text: data.message,
                        icon: "error"
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const verifyCode = (id) => {
        fetch(`${ApiUrl}` + 'verify/from/email/link', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id }),

        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    getData();
                } else {
                    swal({
                        title: "error",
                        text: data.message,
                        icon: "error"
                    });
                }

            }).catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    const resetEmail = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to reset your verified sender identity. By performing this action, you will not be able to send an email from your account.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + 'reset/from/sms/link', {
                        method: 'POST',
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${uToken()}`,
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            getData();

                        }).catch((error) => {
                            setLoaded(true);
                            console.error('Error:', error);
                        });

                }
            })
    }

    //delete 
    const deleteFromSms = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete your record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmation) => {
            if (confirmation) {

                fetch(`${ApiUrl}` + 'delete/from/sms', {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${uToken()}`,
                    },
                    body: JSON.stringify({ id: id }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log("Response:", data);
                        if (data.status === true) {
                            var msg = 'Delete Record Successfully';

                            swal({
                                title: "Success!",
                                text: msg,
                                icon: "success"
                            });
                            getData();
                        } else {
                            swal({
                                title: "error",
                                text: data?.data,
                                icon: "error"
                            });
                        }


                    }).catch((error) => {
                        setLoaded(true);
                        console.error('Error:', error);
                    });
            }
        })


    }

    const defaultAction = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to reset your default status.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmation) => {
            if (confirmation) {

                fetch(`${ApiUrl}` + 'default/set/email', {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${uToken()}`,
                    },
                    body: JSON.stringify({ id: id }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("data success==>", data);

                    }).catch((error) => {
                        setLoaded(true);
                        console.error('Error:', error);
                    });
            }
        })

    }

    const assignedMemberIds = getAssignRecord.map(record => record.coach_partner_id);


    const handleDeleteFromAssign = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete your record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmation) => {
            if (confirmation) {

                fetch(`${ApiUrl}delete/from/assign`, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${uToken()}`,
                    },
                    body: JSON.stringify({ id: id }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log("Response:", data);
                        if (data.status === true) {
                            var msg = 'Delete Record Successfully';

                            swal({
                                title: "Success!",
                                text: msg,
                                icon: "success"
                            });
                            getData();

                            setModal(false);
                        } else {
                            swal({
                                title: "error",
                                text: data?.data,
                                icon: "error"
                            });
                        }
                        setModal(false);

                    }).catch((error) => {
                        setLoaded(true);
                        console.error('Error:', error);
                    });
            }
        })


    }

    const AssignEmailStore = (e, id, from_email) => {
        e.preventDefault();
        fetch(`${ApiUrl}submit/assign/email`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({
                id: id,
                coach_partner_id: selectedMembers,
                from_email: from_email,

            })

        })
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    swal({
                        title: "Success!",
                        text: data.message,
                        icon: "success"
                    });
                    setShowCode(false);

                    setShowLoaded(true);

                    setModal(false);


                } else {
                    swal({
                        title: "error",
                        text: data.message,
                        icon: "error"
                    });
                }
                setModal(false);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };



    const handleSubmit = (id) => (e) => AssignEmailStore(e, id);

    const getAssignData = (coachId, id, from_email, is_share) => {

        fetch(`${ApiUrl}get/assing/email`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({
                coach_id: coachId, id: id,
                from_email: from_email,
                is_share: is_share,

            })
        })
            .then(response => response.json())
            .then((response) => {
                setAssignRecords(response.records);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const defaultStatus = (id, from_email, is_default, from_name, coach_partner_id) => {

        fetch(`${ApiUrl}default/set/email`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({
                id: id,
                from_email: from_email,
                from_name: from_name,
                is_default: is_default,
                coach_partner_id: coach_partner_id
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setShowCode(false);
                    setShowLoaded(true);
                    swal({
                        title: "Success!",
                        text: 'successfully update record',
                        icon: "success"
                    });
                    getData();


                } else {
                    console.log("Error:", data.error); // Log error message
                }

            })
            .catch(error => {
                console.error('Error:', error);
            });

    }


    // console.log("getAssignRecords" , getAssignRecord);

    const columns = [
        {
            text: 'From Name',
            key: 'from_name'
        },
        {
            text: 'From Email',
            key: 'from_email',
        },
        {
            text: 'Status',
            key: 'status',
            cell: (record, index) => {

                if (record.status === 0) {
                    return <p key={index} className="badge bg-danger">Un-Verified</p>
                } else if (record.status === 1) {
                    return <p key={index} className="badge bg-success">Verified</p>
                }
                else if (record.status === 2) {
                    return <p key={index} className="badge bg-primary">Pending</p>
                }
                else if (record.status === 3) {
                    return <p key={index} className="badge bg-danger">Declined</p>
                }


            }

        },
        {
            text: 'Is Default',
            key: 'is_default',
            cell: (record, index) => (
                <div key={index}>
                    {record.status == 1 && setShowLoaded ? (
                        <p>

                            {record.is_default === 1 && setShowLoaded ? (
                                <p className="badge bg-success pe-auto" disabled style={{ cursor: "pointer" }}>
                                    Yes
                                </p>

                            ) : (
                                <p className="badge bg-danger pe-auto " style={{ cursor: "pointer" }} onClick={() => defaultStatus(record.id, record.from_email, record.is_default, record.from_name
                                    , record.coach_partner_id
                                )}>
                                    No
                                </p>
                            )}


                        </p>


                    ) : (
                        <p className="badge bg-danger pe-auto" style={{ cursor: "pointer" }}>No</p>
                    )
                    }
                </div>
            )
        },

        {
            text: 'Created At',
            key: "created_at",

        },

        {
            text: 'Action',
            cell: (record, index) => (
                <div>

                    {record.status === 1 ? (
                        <>

                            {record.is_share === 0 &&
                                <>

                                    <button
                                        className="btn btn-success btn-sm mr-2"
                                        type="button"
                                        onClick={() => {
                                            resetEmail(record.id);
                                        }}
                                    >
                                        <i className="mdi mdi-refresh"></i>
                                    </button>

                                    <input type="hidden" value={record?.id} />
                                    {record.coach_id === record.coach_partner_id &&
                                        <>

                                            <Button
                                                type="button"
                                                className="btn btn-info btn-sm mx-1"
                                                onClick={() => {
                                                    toggleModal(record.id, record.coach_id, record.id, record.from_email, record.is_share, record.coach_partner_id);
                                                    getAssignData(record.coach_id, record.id, record.from_email, record.is_share, record.coach_partner_id);
                                                }}
                                            >
                                                <i className="mdi mdi-share-variant"></i>

                                            </Button>
                                        </>
                                    }
                                </>
                            }
                            <button
                                className="btn btn-primary btn-sm mr-5"
                                type="button"
                                onClick={() => history.push("/from/email/edit/" + bcrypt(record.id))}
                            >
                                <i className="mdi mdi-pencil"></i>
                            </button>
                        </>
                    ) : (
                        <React.Fragment>
                            {record.status === 2 && setShowLoaded ? (
                                <>
                                    <Button type="submit" className="btn btn-sm" color="warning"
                                        onClick={() => {
                                            verifyCode(record.id)
                                        }}>
                                        <i className="mdi mdi-sync"></i>
                                    </Button>
                                    <Button type="submit" className="btn btn-danger btn-sm mx-1 "
                                        onClick={() => {
                                            deleteFromSms(record.id)
                                        }}>
                                        <i className="mdi mdi-trash-can-outline"></i>
                                    </Button>
                                    <input type="hidden" value={record.id} />
                                </>
                            ) : (
                                <React.Fragment>
                                    <button type="submit" className="btn btn-success bg-success btn-sm "
                                        onClick={() => {
                                            verifySetting(record.id)
                                        }}>

                                        <i className="mdi mdi-check-circle-outline"></i>

                                    </button>
                                    <Button type="submit" className="btn btn-danger btn-sm mx-1"
                                        onClick={() => {
                                            deleteFromSms(record.id)
                                        }}>
                                        <i className="mdi mdi-trash-can-outline"></i>

                                    </Button>
                                    <input type="hidden" value={record.id} />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                </div>
            )
        }

    ];


    //is_default








    return (
        <React.Fragment>
            <Row className="mt-lg-3 mb-5">
                <ReactDatatable
                    config={config}
                    records={records}
                    columns={columns}
                />
            </Row>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Assign To</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(assignid)} className="mb-3">

                        <select
                            name="coach_partner_id"
                            value={selectedMembers}
                            onChange={(e) => setSelectedMembers(e.target.value)}
                            className="form-control"

                        >
                            <option value={""}>Select Team Member</option>
                            {memberData?.filter(member => !assignedMemberIds.includes(member.id)).map((item, index) => (
                                <option key={index} value={item.id}>{`${item.name} - ${item.email}`}</option>
                            ))}
                        </select>

                        <Button color="info" className="mt-3" type="submit">Save</Button>

                    </form>



                    <table className="table  table-responsive">
                        <thead>
                            <tr>
                                <th>Sr#</th>
                                <th>Assign to</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getAssignRecord?.reduce((acc, item, index) => {
                                if (item?.is_share === 1) {
                                    acc.push(
                                        <tr key={index}>
                                            <td>{acc.length + 1}</td>
                                            <td>{item?.from_name}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <Button
                                                    type="button"
                                                    className="btn btn-sm mx-2"
                                                    color="danger"
                                                    style={{ backgroundColor: "#EC536C", color: "white" }}
                                                    onClick={() => {
                                                        handleDeleteFromAssign(item.id)
                                                    }}
                                                >
                                                    <i className="mdi mdi-trash-can-outline"></i>                                                </Button>
                                            </td>

                                        </tr>
                                    );
                                }
                                return acc;
                            }, [])}
                        </tbody>
                    </table>
                </ModalBody>

            </Modal>

        </React.Fragment>

    );
};

export default FromEmailTabel;

