import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, Table } from "reactstrap"
import { bcrypt, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export default class BookContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            loader: true,
            page_name: 'Pathway To Profit - W Graphics Book',
            modal: false
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/pathwaytoProfit/book', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        record: response.data.record,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitCustomBook = (event) => {
        this.setState({
            loader: false
        })
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        console.log("submitData" ,  submitData);
       
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
       
        fetch(`${ApiUrl}` + "add/custom/book", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
           
        })
        
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            title: '',
                            description: '',
                            custom_title:'',
                            image: 0
                        },
                        modal: false
                    })
                  
                    this.getData();
                 
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }


    resetBook = () => {
        swal({
            title: "Are you sure?",
            text: "You want to used default book.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + 'add/custom/book', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ` + uToken()
                        },
                        body: JSON.stringify({ 'pdf_book_type': 'Default' }),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response.status === true) {

                                this.getData();

                            } else {
                                swal({
                                    title: response.message,
                                    icon: "warning",
                                });
                            }

                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            });

    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page_name}</title>
                </Helmet>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4> {this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#"> {this.state.page_name}</Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className="page-title-box text-align-right">
                                    {
                                        this.state.record?.pdf_book_type != 'Custom' ? (
                                            <button
                                                onClick={() => this.setState({
                                                    modal: true
                                                })} className="btn btn-sm btn-info" title="Upload custom PDF Book">
                                                Upload Custom Book
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => this.resetBook()} className="btn btn-sm btn-danger" title="Upload custom PDF Book">
                                                Used Default Book
                                            </button>
                                        )
                                    }
                                </div>
                            </Col>

                        </Row>
                        <Card>
                            <CardBody>
                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Preview Link</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.record && (
                                            <tr key={1}>

                                                <td>

                                              {this.state.record?.pdf_book_type === 'Custom' ? 
                                                (this.state.record?.custom_title ?? 'Book(Pathway To Profit - W Graphics)') : 
                                                (this.state.record?.book_title ?? 'Book(Pathway To Profit - W Graphics)')}
                                                
                                                </td>
                                                <td>{this.state.record?.pdf_book_type ?? 'Default'}</td>
                                                {
                                                    this.state.record?.pdf_book_type != 'Custom' ?
                                                        <td>
                                                            <a className="border-0" href={`${this.state.record?.book_portrait}`} target={'_blank'}>
                                                                <i class="fas fa-file-pdf"></i> Preview
                                                            </a>
                                                        </td>
                                                        :

                                                        <td>
                                                            <a className="border-0" href={`${this.state.record?.book_portrait_custom}`} target={'_blank'}>
                                                                <i class="fas fa-file-pdf"></i> Preview
                                                            </a>
                                                        </td>
                                                }

                                                {
                                                    this.state.record?.pdf_book_type != 'Custom' ?
                                                        <td>
                                                            <a className="border-0 ml-3" href={`${this.state.record?.book_landscape}`} title={"Download PDF Lanscape Orientation Layout"} target={'_blank'}>
                                                                <i class="fas fa-download"></i>
                                                            </a>
                                                            <a className="border-0 ml-5" href={`${this.state.record?.book_portrait}`} title={"Download PDF Portrait Orientation Layout"} target={'_blank'}>
                                                                <i class="fas fa-cloud-download-alt ml-2"></i>
                                                            </a>
                                                        </td>
                                                        :
                                                        <td>
                                                            <a className="border-0 ml-3" href={`${this.state.record?.book_landscape_custom}`} title={"a PDF Lanscape Orientation Layout"} target={'_blank'}>
                                                                <i class="fas fa-download"></i>
                                                            </a>
                                                            <a className="border-0 ml-5" href={`${this.state.record?.book_portrait_custom}`} title={"a PDF Portrait Orientation Layout"} target={'_blank'}>
                                                                <i class="fas fa-cloud-download-alt ml-2"></i>
                                                            </a>
                                                        </td>
                                                }

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>

                    <Modal
                        isOpen={this.state.modal}
                        fade={false}
                        toggle={() => this.setState({ modal: false })}
                        backdrop={'static'}
                    >
                        <form onSubmit={this.submitCustomBook} method="post"
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <ModalBody>
                                <Row>
                                    <input
                                        name="pdf_book_type"
                                        defaultValue={'Custom'}
                                        type='hidden'
                                    />
                                    <Col md="12">
                                       <div className="form-group mb-3">
                                       <label>Custom Book Title</label>
                                         <input name="custom_title" type="text" className="form-control" placeholder="enter your custom Book Title" />
                                       
                                       </div>
                                        <div className="form-group mb-3">
                                            <label>Custom Book PDF</label>
                                            <input
                                                name="file"
                                                className="form-control"
                                                placeholder=""
                                                type={'file'}
                                                accept={'application/pdf'}
                                                requured
                                            />
                                        </div>
                                        <note className={'text-danger'}>Make sure file size less than 10MB</note>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" type="button" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                                <Button className="Green" type="submit" disabled={!this.state.loader ? true : false}>Submit</Button>
                                
                            </ModalFooter>
                        </form>
                    </Modal>

                </Row>

            </React.Fragment >
        )
    }
}