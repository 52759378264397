import React, { useEffect, useRef, useState } from 'react';
import Loader from "react-loader";
import swal from 'sweetalert';
import { ApiUrl, AttachementsUrl } from '../../config';
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';


import WayBookContext from './WaybookContext';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'

import 'froala-editor/js/plugins/inline_style.min.js'
import "froala-editor/js/plugins/align.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "froala-editor/js/plugins/colors.min.js"
import "froala-editor/js/plugins/emoticons.min.js"
import "froala-editor/js/plugins/draggable.min.js"
import "froala-editor/js/plugins/font_size.min.js"
import "froala-editor/js/plugins/font_family.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/image_manager.min.js"
import "froala-editor/js/plugins/line_breaker.min.js"
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/lists.min.js"
import "froala-editor/js/plugins/paragraph_format.min.js"
import "froala-editor/js/plugins/paragraph_style.min.js"
import "froala-editor/js/plugins/video.min.js"
import "froala-editor/js/plugins/table.min.js"
import "froala-editor/js/plugins/url.min.js"
import "froala-editor/js/plugins/emoticons.min.js"
import "froala-editor/js/plugins/file.min.js"
import "froala-editor/js/plugins/entities.min.js"
import "froala-editor/js/plugins/inline_style.min.js"
import "froala-editor/js/plugins/save.min.js"
import "froala-editor/js/plugins/fullscreen.min.js"


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/code_view.css';
import 'froala-editor/css/plugins/image_manager.css';
import 'froala-editor/css/plugins/image.css';
import 'froala-editor/css/plugins/table.css';
import 'froala-editor/css/plugins/video.css';
import Froala from '../Froala';
import TraningQuiz from './trainingQuiz';
import UrlNextPrev from './UrlNextPrev';

function DocumentContents(props) {
    const params = React.useContext(WayBookContext);
    const [setupreview] = useState(props.setupreview ? true : false);
    const [loaded, setLoaded] = useState(false);
    const [is_publish, setIsPublish] = useState(null);
    const [list, setList] = useState({});
    const [documentList, setDocumentList] = useState({});
    const [is_step_id] = useState(props.id[6] ? props.id[6] : null);

    const { search } = useLocation();
    const url_params = new URLSearchParams(search);
    const is_coach_section = url_params.get('sec') ? 'Yes' : "No";


    useEffect(() => {
        getWayBookStepsLists();
    }, []);

    const handleSubmit = (event, step_content) => {
        fetch(`${ApiUrl}` + "update/waybook/steps/content/" + params.documents, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ step_id: params.step ? params.step : list.step_id, step_content: step_content,is_publish:is_publish }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    swal({
                        title: response.message,
                        icon: "success",
                    });


                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getWayBookStepsLists = () => {
        fetch(`${ApiUrl}get/waybook/steps/${params.documents}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({
                is_coach_section: is_coach_section,
                step_id: params.step,
                can_edit: params.can_edit || params.can_edit_coach ? true : false,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setList(response.data.record);
                    setDocumentList(response.data.document);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };

    const handleMarkAsComplete = (documents, stepId) => {
        swal({
            title: "Are you sure",
            text: "you want to mark this training as incomplete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmation) => {
            if (confirmation) {
                params.documentLogsReset(documents, stepId);
            }
        });
    };


    return (


        <div>


            <Loader loaded={loaded}></Loader>
            <div className='row'>
                <div className='col-lg-12 ps-0'>
                    <div className='way-header-right'>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>

                    {setupreview ? <>
                        {props.lists && (<TraningQuiz title={list.title} params={params} list={list} lists={props.lists} documentList={documentList} />)}
                    </> :
                        <>

                            <div className='TraningRightContent p-0'>
                                <div className="d-flex justify-content-between align-items-center position-relative TopTitle">
                                    <h4 className="mb-0">{documentList.title}</h4>
                                    <div className='ProgressButtons'>
                                    {!params.can_edit && uInfo().type !== "WlAdmin" && !(params.can_edit_coach && is_coach_section === 'Yes') && ( 
                                        <div className='RightAreaBtn d-flex align-items-center'>
                                            <div className="progress w-100 me-1">
                                            <div 
                                                role="progressbar" 
                                                className="progress-bar" 
                                                aria-valuemin={documentList.percentage} 
                                                aria-valuemax="100" 
                                                aria-valuenow={documentList.percentage} 
                                                style={{ width: `${documentList.percentage}%` }}
                                            />
                                            </div>
                                            <span>{documentList.percentage}%</span>
                                        </div>
                                    )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center position-relative border-bottom p-2">
                                    <h5 className="mb-0">{list.title}</h5>
                                    <div className='ProgressButtons'>
                                        <div className='d-flex justify-content-end align-items-center'>

                                            {
                                                props.lists.map((item, i) => (
                                                    item.sub_cat.map((sub_item, k) => (
                                                        sub_item.sub_cat.map((sub, m) => (
                                                            sub.sub_steps && sub.sub_steps.map((step, n) => (

                                                                <UrlNextPrev
                                                                    prevlink={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}/`}

                                                                    nextlink={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}/`}

                                                                    link={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}/`}
                                                                    nvalue={n}
                                                                    docstep={sub.sub_steps}
                                                                    list={list}
                                                                    stepCount={sub.sub_steps.length}
                                                                    is_coach_section={is_coach_section}
                                                                    can_edit_coach={params.can_edit_coach}
                                                                />


                                                            ))
                                                        ))
                                                    ))
                                                ))
                                            }


                                            {(params.can_edit || (params.can_edit_coach && documentList.user_id !== 2)) && list.step_id > 0 && (

                                                <>
                                                    {list.status === 'Publish' ? (
                                                        <div className='edit-step-action'>
                                                            <button className='BackBtn btn btn-secondary submit-step-content mx-2 btn-sm' id='submit-step-content'>Save</button>
                                                        </div>
                                                    ) : (
                                                        <div className='edit-step-action'>
                                                            <button className='BackBtn btn btn-secondary submit-step-content mx-2 btn-sm' id='submit-step-content'>Save as Unpublish</button>
                                                        </div>
                                                    )}


                                                    {list.status === 'Publish' ? (
                                                        <div className='NextBackBtn edit-step-action'>
                                                          <button 
                                                          className='BackBtn btn btn-secondary btn-sm' style={{ 'margin-right': '7px' }}
                                                          onClick={() => {
                                                              params.stepMethodAction('Step', list.step_id, list.title, 'Draft', list.training_for, list.tag_training);
                                                              const element = document.getElementById('submit-step-content');
                                                              if (element) {
                                                              element.click();
                                                              }
                                                              
                                                          }}
                                                          >
                                                          Unpublish
                                                          </button>
                                                        </div>
                                                    ) : (
                                                        <div className='NextBackBtn edit-step-action'>
                                                            <button 
                                                           className='BackBtn btn btn-secondary btn-sm' style={{ 'margin-right': '7px' }}
                                                            onClick={() => {
                                                                params.stepMethodAction('Step', list.step_id, list.title, 'Publish', list.training_for, list.tag_training);
                                                                const element = document.getElementById('submit-step-content');
                                                                if (element) {
                                                                element.click();
                                                                }
                                                                
                                                            }}
                                                            >
                                                            Publish & Go Live
                                                            </button>
                                                        </div>
                                                    )}


                                                    <div className='NextBackBtn edit-step-action'>
                                                        <button className='BackBtn btn btn-primary btn-sm' style={{ 'margin-right': '7px' }}
                                                            onClick={() => params.stepMethodAction('Step', list.step_id, list.title, 'Delete', list.training_for, list.tag_training)}
                                                        >Delete</button>
                                                    </div>
                                                    
                                                </>
                                            )}
                                            <div className='create-step'>
                                                {
                                                    (params.can_edit || (params.can_edit_coach && documentList.user_id !== 2)) && (
                                                        <div className='add-new-step'>
                                                            <div className='add-step-btn text-center w-100'>
                                                                <button onClick={() => params.subjectOrDocumentAction('Step', params.documents, documentList.title, 'AddStep','','','','','center')} className='bwaves-effect waves-light BackBtn btn btn-primary w-100 text-nowrap btn-sm'>New Training</button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (params.can_edit || (params.can_edit_coach && documentList.user_id !== 2)) ?
                                        <>
                                            <div className={list.step_id > 0 ? '' : 'd-none'}>
                                                {
                                                    list.step_id > 0 && (
                                                        <>
                                                            <Froala
                                                                inlineEditior={false}
                                                                btnId={'submit-step-content'}
                                                                content={list.content}
                                                                handleSubmit={handleSubmit}
                                                                use_token={'users'}

                                                                config={{
                                                                    pluginsEnabled: ['pdf', 'image', 'link', 'video'],
                                                                    toolbarButtons: ['pdf', 'bold', 'italic', 'underline', 'insertLink', 'insertImage', 'insertVideo'],
                                                                }}
                                                            />
                                                            
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div className={list.step_id ? 'd-none' : 'add-new-step'}>
                                                <div className='add-step-btn text-center mt-3 w-100'>
                                                    {/* <button onClick={() => params.subjectOrDocumentAction('Step', params.documents, documentList.title, 'AddStep')} className='bwaves-effect waves-light btn btn-outline-dark'> New Step</button> */}
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            <div className={`TextEditor fr-view`}>
                                                <div className="TraningContent" dangerouslySetInnerHTML={{ __html: list.body }} />
                                                <div className={`${list.step_id > 0 ? 'd-inline-block w-100' : 'd-none'}`}>
                                                    {
                                                        list.log_id > 0 ? (
                                                            <div className='not-edit-action'>
                                                                <div className='btn-mark text-center mb-3'>
                                                                    <button disabled className='btn btn-success'>Completed</button>
                                                                </div>
                                                                <div className='btn btn-unmark text-center w-100'>
                                                                    <button onClick={() => handleMarkAsComplete(params.documents, list.step_id)} className='btn btn-danger UndoCompleted' style={{ 'color': '#fff' }}>Undo</button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className={`not-edit-action mt-3 text-center d-flex justify-content-center ${(uInfo().user_id == 2 || is_coach_section === 'Yes') && ('d-none')}`}>
                                                                <div className='w-75'>
                                                                    <h6>If you've read and understand everything in this training you can mark it as completed.
                                                                        This will help you track your progress on this lesson.
                                                                    </h6>
                                                                    <div className='btn-mark mt-3 mb-3'>
                                                                        <button onClick={() => params.markAsComplete(params.documents, list.step_id)} className='btn btn-primary'>Mark as completed</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        </>
                                }
                            </div>
                        </>}

                </div>
            </div>

            <div className='row'>
                <div className='col-lg-2'>

                </div>
            </div>
        </div>

    );
}

export default DocumentContents;